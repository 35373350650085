<template>
  <div class="page-content-padded page-merch-view" v-if="merch">
    <div class="page-main page-merch">
      <div class="main-info">
        <div class="links-block">
          <router-link :to="'/user/' + app.user.path" v-if="merch && app?.user">
          <div class="merch-image">
            <div class="merch-label">
              <i class="fa fa-arrow-left" aria-hidden="true"></i> Go back to profile
            </div>
          </div>
        </router-link>
        <a :href="merch?.links[0].link">
          <div class="merch-image">
            <div class="merch-label">
              Go to Merch <i class="fa fa-arrow-right" aria-hidden="true"></i> 
            </div>
          </div>
        </a>
        </div>
        
        <div class="item-info">
          <div class="item-info-upper">
            <div class="item-info-upper-left">

              <div class="merch-body">
                <h2>{{ merch.title }}</h2>
                <p>{{ merch.description }}</p>
              </div>
              <div v-if="merch.image" class="merch-body">
                <img v-if="merch.image" :src="merch.image.files.medium.url" :alt="merch.image.alt"
                  class="p-merch-image" />
              </div>
              <div class="merch-author">
                <h2>By</h2>
                <router-link :to="'/user/' + merch.user.path" style="display: flex;gap: 5px">
                  <avatar :src="merch.user.avatar.files.medium.url" :size="50" :center="false" />
                  <h3>
                    {{ merch.user.name }}
                  </h3>
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <dotlottie-player v-else src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
    background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto;" loop
    autoplay></dotlottie-player>
</template>

<script>
import { mapState } from 'vuex';
import SidebarGroup from 'global/sidebar-group';
import store from 'store';
// import CommentsContainer from "global/comments-container";

export default {
  components: {
    SidebarGroup,
    // CommentsContainer,
  },
  data() {
    return {
      merch: null,
    }
  },
  created: function () {
    this.fetchMerch();
  },
  methods: {
    fetchMerch() {
      axios.get('/api/merch/' + this.$route.params.merchid).then(response => {
        this.merch = response.data;
      });
    }
  },
  computed: mapState([
    'app',
  ])
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h1 {
    font-size: 35px;
}

.page-merch {
    padding: 3em;
}

.main-info {
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

.merch-label {
    background-color: #e6e6e6;
    font-weight: bold;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 10px 12px;
    max-width: 168px;
}

.item-info {
    margin-left: 1em;
    flex: 1;
}

.item-info-upper {
    display: flex;
}

.item-info-upper-left {
    flex: 1;
}

h1 {
    margin: 0;
}

.merch-body {
    font-size: 20px;
    margin: 1em 0;
    color: $color-grey2;
    line-height: 30px;
}

td {
    padding: 15px 0;
    text-align: center;
}

.comments {
    margin: 3em 0;
}

.merch-author {
    display: inline-flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
}

.merch-author h2 {
    color: $color-grey2;
}

.links-block{
  display: flex;
  justify-content: space-between;
}
</style>
