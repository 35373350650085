var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-upload",
        width: "85%",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-upload" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("logo", { staticClass: "modal-logo centered-block" }),
            _vm._v(" "),
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.closeModal.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content" },
          [
            _vm.mode !== "upload"
              ? _c("div", { staticClass: "modal-content__header" }, [
                  _c(
                    "h1",
                    { staticClass: "centered-text modal-content__heading" },
                    [_vm._v("Upload a new release")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "centered-text upload-info" }, [
                    _vm._v("You can upload in either WAV or Mp3"),
                  ]),
                ])
              : _c("upload-progress", { attrs: { upload: _vm.upload } }),
            _vm._v(" "),
            !_vm.isValidUpload
              ? _c(
                  "div",
                  { staticClass: "upload-error" },
                  [
                    _vm._v(
                      "\n        Sorry, you have reached your upload limit, you will be able to upload again on " +
                        _vm._s(_vm.nextUploadDate) +
                        ", or\n        upgrade\n        to PRO for unlimited uploads\n        "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "ph-button",
                      {
                        attrs: {
                          size: "medium",
                          width: "100%",
                          loading: _vm.loading,
                          color: "primary-outline",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.upgradeToPro()
                          },
                        },
                      },
                      [_vm._v("Upgrade")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.mode !== "upload" && _vm.isValidUpload
              ? _c("div", { staticClass: "upload-main" }, [
                  _c("div", { staticClass: "upload-main" }, [
                    _vm.cover.class !== "single"
                      ? _c("div", { staticClass: "upload-nav" }, [
                          _c(
                            "div",
                            { staticClass: "draggable-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "draggable-item cover",
                                  class: { active: _vm.mode === "cover" },
                                  on: { click: _vm.editCover },
                                },
                                [
                                  _vm._v(
                                    "\n                Cover\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.coverValid || _vm.cover.class === "single"
                                ? [
                                    _c(
                                      "draggable",
                                      {
                                        on: { update: _vm.updateTrackNumber },
                                        model: {
                                          value: _vm.tracks,
                                          callback: function ($$v) {
                                            _vm.tracks = $$v
                                          },
                                          expression: "tracks",
                                        },
                                      },
                                      _vm._l(
                                        _vm.tracks,
                                        function (track, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: track.id,
                                              staticClass:
                                                "draggable-item track",
                                              class: {
                                                active:
                                                  _vm.mode === "track" &&
                                                  _vm.currentTrack.id ===
                                                    track.id,
                                              },
                                              on: {
                                                mousedown: function ($event) {
                                                  return _vm.editTrack(track.id)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(index + 1) +
                                                  ". " +
                                                  _vm._s(
                                                    track.title || "New Track"
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _vm.tracks.length > 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "track-delete",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteTrack(
                                                            track.id,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.userCanUpload &&
                                    _vm.coverValid &&
                                    !_vm.maxTracks,
                                  expression:
                                    "userCanUpload && coverValid && !maxTracks",
                                },
                              ],
                              staticClass: "add-track",
                              on: { click: _vm.addTrack },
                            },
                            [_c("i", { staticClass: "fa fa-plus-circle" })]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "upload-input",
                        style:
                          _vm.cover.class === "single"
                            ? { paddingLeft: "5em" }
                            : null,
                      },
                      [
                        _c("cover-info", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mode === "cover",
                              expression: "mode === 'cover'",
                            },
                          ],
                          attrs: {
                            cover: _vm.cover,
                            trackCount: _vm.tracks.length,
                            currentTrack: _vm.currentTrack,
                          },
                          on: {
                            featuredDates: function ($event) {
                              _vm.cover.featuredDates = _vm.data
                            },
                            onSubmit: _vm.handleCoverSubmit,
                          },
                        }),
                        _vm._v(" "),
                        _vm.mode === "track" && _vm.coverValid
                          ? _c("track-info", {
                              key: _vm.currentTrackNumber,
                              attrs: {
                                currentTrack: _vm.currentTrack,
                                tracks: _vm.tracks,
                                currentTrackNumber: _vm.currentTrackNumber,
                                trackError: _vm.trackError,
                              },
                              on: {
                                onSubmit: function ($event) {
                                  return _vm.validateFormData(true)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }