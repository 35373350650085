var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      class: "masonry-item " + +_vm.item.component + "-" + _vm.item.id,
      attrs: { to: _vm.getRouterObject(_vm.item) },
    },
    [
      _c("img", {
        staticClass: "masonry-image masonry-image-release",
        attrs: { src: _vm.imgUrl, alt: this.item.name },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "masonry-inner masonry-inner-fixed" }, [
        _c("h4", [_vm._v("Merch")]),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.item.title))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }