var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post
    ? _c("div", { staticClass: "page-content-padded" }, [
        _c("div", { staticClass: "page-main page-post" }, [
          _c(
            "div",
            { staticClass: "main-info" },
            [
              _vm.post && _vm.app?.user
                ? _c(
                    "router-link",
                    { attrs: { to: "/user/" + _vm.app.user.path } },
                    [
                      _c("div", { staticClass: "post-image" }, [
                        _c("div", { staticClass: "post-label" }, [
                          _c("i", {
                            staticClass: "fa fa-arrow-left",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" Go back to profile\n                    "),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "item-info" }, [
                _c("div", { staticClass: "item-info-upper" }, [
                  _c("div", { staticClass: "item-info-upper-left" }, [
                    _c("div", { staticClass: "post-body" }, [
                      _vm._v(
                        "\n\n                            " +
                          _vm._s(_vm.post.body) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.post.attachment
                      ? _c("div", { staticClass: "post-body" }, [
                          _vm.post.attachment
                            ? _c("img", {
                                staticClass: "p-post-image",
                                attrs: {
                                  src: _vm.post.attachment.files.medium.url,
                                  alt: _vm.post.attachment.alt,
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "post-author" },
                      [
                        _c("h2", [_vm._v("By")]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticStyle: { display: "flex", gap: "5px" },
                            attrs: { to: "/user/" + _vm.post.user.path },
                          },
                          [
                            _c("avatar", {
                              attrs: {
                                src: _vm.post.user.avatar.files.medium.url,
                                size: 50,
                                center: false,
                              },
                            }),
                            _vm._v(" "),
                            _c("h3", [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.post.user.name) +
                                  "\n                                "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "comments" },
            [_c("comments-container", { attrs: { commentable: _vm.post } })],
            1
          ),
        ]),
      ])
    : _c("dotlottie-player", {
        staticStyle: { width: "200px", height: "200px", margin: "auto" },
        attrs: {
          src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }