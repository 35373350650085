var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        "masonry-item " +
        (_vm.selectedCategory == "track" ? "w-300 " : "") +
        _vm.item.component +
        "-" +
        _vm.item.id,
      style: `background-image:linear-gradient(to top, rgba(255,0,0,0), rgb(0, 0, 0)), url('${_vm.mutableTrack.release.image.files.medium.url}');`,
    },
    [
      _c(
        "div",
        { staticClass: "masonry-inner" },
        [
          _c("div", {}, [
            _c("h4", [_vm._v("Track")]),
            _vm._v(" "),
            _c("h2", { staticStyle: { color: "#fff" } }, [
              _vm._v(_vm._s(_vm.item.name)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
            },
            [
              _c("ul", { staticClass: "list-unstyled simple-list" }, [
                _c("li", [
                  _vm._v(
                    "\n                    Release:\n                    "
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.item.release.name))]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n                    BPM:\n                    "),
                  _c("span", [_vm._v(_vm._s(_vm.item.bpm))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {}),
            ]
          ),
          _vm._v(" "),
          _c("avatar-track", {
            attrs: {
              "show-image": false,
              size: 70,
              tile: true,
              src: _vm.mutableTrack.release.image.files.medium.url,
              track: _vm.mutableTrack,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "trackProgress",
              staticClass: "p-item-track-progress clearfix",
              on: {
                click: _vm.doClick,
                mousedown: _vm.startDrag,
                mousemove: _vm.doDrag,
                mouseup: _vm.endDrag,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-item-track-progress-bar",
                  style: "width: " + _vm.track.progress + "%",
                },
                [_c("div", { staticClass: "p-item-track-playhead" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "button foot_button mint small",
              attrs: { to: _vm.getRouterObject(_vm.item) },
            },
            [_vm._v("\n            Track Details\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }