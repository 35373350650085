var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subscription-plan" }, [
    _c("div", { staticClass: "plan-info" }, [
      _c("p", { style: _vm.paragraphStyle }, [
        _c("em", [_vm._v(_vm._s(_vm.status))]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "plan-subscribe" },
      [
        _vm.button === "cancel" && _vm.app.user.approved_at
          ? _c(
              "ph-button",
              {
                attrs: {
                  size: "small",
                  loading: _vm.loading,
                  disabled: _vm.disabledbtn,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.unsubscribe.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n      Cancel\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }