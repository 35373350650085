var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "250px",
        height: "250px",
        viewBox: "0 0 250 250",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v("Information Processed")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Static icon for processed information")]),
      _vm._v(" "),
      _c(
        "g",
        { attrs: { id: "checkmark_group", transform: "translate(20, 20)" } },
        [
          _c("polyline", {
            attrs: {
              points: "50,125 100,175 200,75",
              fill: "none",
              stroke: "#28a745",
              "stroke-width": "20",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }