var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loadingTrack.users
      ? _c("div", [
          _c("h6", [
            _vm._v("Users: " + _vm._s(_vm.resultCounts.user) + " Results"),
          ]),
          _vm._v(" "),
          _vm.users.length
            ? _c(
                "ul",
                { staticClass: "list" },
                [
                  _vm._l(_vm.users, function (result, index) {
                    return _c(
                      "li",
                      { staticClass: "item" },
                      [
                        _c("artist-tile", {
                          attrs: { user: result, size: 150 },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("li", { staticClass: "load-more-icon" }, [
                    _vm.counts.user > _vm.pages.user
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingUserMore,
                                expression: "!loadingUserMore",
                              },
                            ],
                            staticClass: "btn",
                            on: { click: _vm.userLoad },
                          },
                          [_vm._v("Load\n                    More")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingUserMore,
                            expression: "loadingUserMore",
                          },
                        ],
                        staticClass: "more-search-loading",
                      },
                      [
                        _c("dotlottie-player", {
                          staticStyle: { width: "150px", height: "150px" },
                          attrs: {
                            src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                            background: "transparent",
                            speed: "1",
                            loop: "",
                            autoplay: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px", margin: "auto" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        ),
    _vm._v(" "),
    !_vm.loadingTrack.releases
      ? _c("div", [
          _c("h6", [
            _vm._v(
              "Releases: " + _vm._s(_vm.resultCounts.release) + " Results"
            ),
          ]),
          _vm._v(" "),
          _vm.releases.length
            ? _c(
                "ul",
                { staticClass: "list" },
                [
                  _vm._l(_vm.releases, function (result, index) {
                    return _c(
                      "li",
                      { staticClass: "item" },
                      [
                        _c("release-tile", {
                          attrs: { release: result, size: 150 },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("li", { staticClass: "load-more-icon" }, [
                    _vm.counts.release > _vm.pages.release
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingReleaseMore,
                                expression: "!loadingReleaseMore",
                              },
                            ],
                            staticClass: "btn",
                            on: { click: _vm.releaseLoad },
                          },
                          [_vm._v("Load\n                    More")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingReleaseMore,
                            expression: "loadingReleaseMore",
                          },
                        ],
                        staticClass: "more-search-loading",
                      },
                      [
                        _c("dotlottie-player", {
                          staticStyle: { width: "150px", height: "150px" },
                          attrs: {
                            src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                            background: "transparent",
                            speed: "1",
                            loop: "",
                            autoplay: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px", margin: "auto" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        ),
    _vm._v(" "),
    !_vm.loadingTrack.tracks
      ? _c("div", [
          _c("h6", [
            _vm._v("Tracks: " + _vm._s(_vm.resultCounts.track) + " Results"),
          ]),
          _vm._v(" "),
          _vm.tracks.length
            ? _c(
                "ul",
                { staticClass: "list" },
                [
                  _vm._l(_vm.tracks, function (result, index) {
                    return _c(
                      "li",
                      { staticClass: "item" },
                      [
                        _c("track-tile", {
                          attrs: { track: result, size: 150 },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("li", { staticClass: "load-more-icon" }, [
                    _vm.counts.track > _vm.pages.track
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingTrackMore,
                                expression: "!loadingTrackMore",
                              },
                            ],
                            staticClass: "btn",
                            on: { click: _vm.trackLoad },
                          },
                          [_vm._v("Load\n                    More")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingTrackMore,
                            expression: "loadingTrackMore",
                          },
                        ],
                        staticClass: "more-search-loading",
                      },
                      [
                        _c("dotlottie-player", {
                          staticStyle: { width: "150px", height: "150px" },
                          attrs: {
                            src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                            background: "transparent",
                            speed: "1",
                            loop: "",
                            autoplay: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px", margin: "auto" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }