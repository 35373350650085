<template>
    <div class="p-item">
        <div class="p-item-image">
            <avatar :size="130" :src="post.user.avatar.files.thumb.url" />
        </div>
        <div class="p-item-main">
            <input type="hidden" v-model="post.type" />

            <div class="p-post-text">

                <!-- Edit Mode -->
                <div class="p-item-detail">
                    <textarea v-if="isEditing" v-model="editedPostBody" class="form-control post-editable-input" rows="5"
                        cols="60"></textarea>
                </div>

                <!-- View Mode -->
                <div v-if="postBody != '' && !isEditing" class="p-item-detail">
                    {{ postBody }}
                </div>

                <div class="p-post-text p-post-attachment" v-if="post.attachment">
                    <img v-if="post.attachment" :src="post.attachment.files.medium.url" :alt="post.attachment.alt"
                        class="p-post-image" />

                </div>
            </div>
            <div class="p-item-meta">
                <actions :actionable="post" :id="id" :actiontype='"post"' @edit-post="enableEditMode" v-if="!isEditing">
                </actions>
                <div class="p-item-detail post-editable-actions" v-if="isEditing">
                    <ph-button @click.native="savePost" size="small" :loading="submitting"
                        color="primary-outline">Submit</ph-button>
                    <ph-button @click.native="cancelEdit" size="small" color="danger-outline">Cancel</ph-button>
                </div>
                <div class="p-item-time">
                    <span v-if="post.updated_at > post.created_at">
                        {{ moment(post.updated_at).fromNow() }} (edited)
                    </span>
                    <span v-else>
                        {{ moment(post.created_at).fromNow() }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Actions from 'global/actions/actions';
import ActionMenu from 'global/actions/action-menu';
import Avatar from 'global/avatar';
import { SocialEvents } from "events";

export default {
    props: {
        post: {
            type: Object,
            required: true,
        },
        id: {
            type: Number,
            default: null,
        }
    },

    data() {
        return {
            moment: window.moment,
            isEditing: false,
            editedPostBody: '',
            submitting: false,
        }
    },
    created: function () {
        this.updateType();
    },
    computed: {
        postBody() {
            if (this.post.body) {
                return this.post.body
            } else {
                return '';
            }
        }
    },
    methods: {
        updateType() {
            this.post.type = 'post';
        },
        enableEditMode() {
            this.isEditing = true;
            this.editedPostBody = this.post.body;
        },
        savePost() {
            if (this.editedPostBody.trim()) {
                let data = new FormData();
                data.append("body", this.editedPostBody.trim());

                this.submitting = true;
                axios
                    .post(`/api/user/posts/${this.post.id}/update`, data)
                    .then((response) => {
                        this.$modal.hide("modal-update-post");
                        this.$notify({
                            group: "main",
                            type: "success",
                            title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                            duration: 1500,
                        });
                    })
                    .finally(() => {
                        this.submitting = false;
                        this.isEditing = false;
                        SocialEvents.$emit("activity-update");
                        SocialEvents.$emit("post-update");
                    });
            }
        },
        cancelEdit() {
            this.isEditing = false;
        }

    },
    components: {
        Actions,
        ActionMenu,
        Avatar,
    }
}
</script>

<style lang="scss" scoped>
.p-post-text {
    flex: 1;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2em;
}

.p-post-attachment {
    margin-top: 8px;
}

.p-item-title {
    font-size: 19px;
}

.p-item-main {
    justify-content: flex-start;
}

.p-post-image {
    height: 120px;
    width: auto;
    margin-bottom: 1em;
}

.post-editable-input {
    width: 100%;
    border: 1px solid #ffff;
    border-radius: 5px;
}

.post-editable-actions{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>
