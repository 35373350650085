var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: "masonry-item " + _vm.item.component + "-" + _vm.item.id,
      style: `min-height:250px;background-size:cover;background-repeat:no-repeat;background-image:linear-gradient(to top, rgba(255,0,0,0), rgb(0, 0, 0)), url('${_vm.imgUrl}')`,
      on: {
        mouseover: function ($event) {
          _vm.hovered = _vm.item.id
        },
        mouseleave: function ($event) {
          _vm.hovered = null
        },
      },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hovered === _vm.item.id,
                expression: "hovered === item.id",
              },
            ],
            staticClass: "overlay",
          },
          [
            _c(
              "div",
              { staticClass: "button-container" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button foot_button mint small",
                    attrs: { to: _vm.getRouterObject(_vm.item) },
                  },
                  [_vm._v("View Release")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "masonry-item-footer" },
              [
                _c("actions", {
                  attrs: { actionable: _vm.item },
                  on: { like: _vm.liked, unlike: _vm.unliked },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "masonry-inner masonry-inner-fixed" }, [
        _c("h4", [_vm._v("Release")]),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.item.name))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.limitChars(_vm.item.description)))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }