var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "masonry-item " + _vm.item.component + "-" + _vm.item.id },
    [_c("genre-logo", { attrs: { genre: _vm.item } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }