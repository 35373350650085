<template>
  <modal name="modal-update-merch" width="800px" height="auto" scrollable adaptive @before-open="beforeOpen">
    <div class="modal modal-update-merch">
      <div class="modal-header">
        <close-icon class="float-right" @click.native="$modal.hide('modal-update-merch')"></close-icon>
      </div>
      <div class="modal-content content-section">
        <h2>Update merch</h2>
        <div class="merch-options">
          <div class="merch-image">
            <avatar v-if="merch && !editImage" :size="247" :src="merch.image.files.medium.url" :tile="true" />
            <image-select v-model="data.image" v-validate="'min-dimensions:300,300'" name="image" v-if="editImage" />
            <div class="edit-image-button">
              <ph-button size="medium" @click.native.prevent="editImage = !editImage" v-if="!editImage">Change
                Image</ph-button>
            </div>
            <span class="error-msg">{{ errors.first("image") }}</span>
          </div>
          <div class="event-info">
            <form>
              <table>
                <tr>
                  <td>Title</td>
                  <td>
                    <input type="text" name="title" placeholder="Title" v-model="data.title"
                      v-validate="'required|max:255'" />
                    <span class="error-msg">{{ errors.first("title") }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>
                    <input type="text" name="description" placeholder="Description" v-model="data.description"
                      v-validate="'required|max:255'" />
                    <span class="error-msg">{{
                      errors.first("description")
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Links</td>
                  <td>
                    <input type="link" name="link" placeholder="Link (http://example.com)" v-model="data.link"
                      v-validate="'required|url|max:255'" />
                    <span class="error-msg">{{ errors.first("link") }}</span>
                  </td>
                </tr>
              </table>
            </form>
            <ph-button @click.native="submit" size="medium" :loading="submitting"
              color="primary-outline">Submit</ph-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseIcon from "global/close-icon";
import ImageSelect from "global/image-select";
import { UserEvents, SocialEvents } from "events";
import Avatar from "global/avatar";

export default {
  data() {
    return {
      merch: "",
      data: {
        image: null,
        title: "",
        link: null,
        description: "",
      },
      submitting: false,
      editImage: false,
    };
  },
  created: function () { },
  mounted: function () { },
  methods: {
    beforeOpen({ params }) {
      this.initForm(params.merch);
      this.merch = params.merch;
    },

    resetForm() {
      // Reset all the states to their initial values
      this.merch = null;
      this.data = {
        image: null,
        title: "",
        link: null,
        description: "",
      };
      this.editImage = false;
      this.submitting = false;
    },

    initForm(merch) {
      this.data.title = merch.title;
      this.data.link = merch.links[0].link;
      this.data.description = merch.description;
    },

    submit() {
      this.$validator.validateAll().then((passes) => {
        if (!passes) return;
        let data = new FormData();
        if (this.data.image) {
          data.append("image", this.data.image[0]);
        }
        data.append("title", this.data.title);
        data.append("description", this.data.description);
        data.append(`links[0][link]`, this.data.link);

        this.submitting = true;
        axios
          .post(`/api/merch/${this.merch.id}/update`, data)
          .then((response) => {
            this.$modal.hide("modal-update-merch");
            this.$notify({
              group: "main",
              type: "success",
              title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
              duration: 1500,
            });
          })
          .finally(() => {
            this.submitting = false;
            UserEvents.$emit("merch-added");
            SocialEvents.$emit("activity-update");
          });
      });
    },
  },
  components: {
    CloseIcon,
    ImageSelect,
    Avatar,
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.edit-image-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

h2 {
  margin-bottom: 0;

  @media (max-width: 414px) {
    margin-top: 1.5em;
  }
}

p {
  margin: 1em 0;
}

.event-options {
  display: flex;

  @media (max-width: 655px) {
    flex-direction: column;
  }
}

.event-info {
  margin-left: 20px;
  flex: 1;

  @media (max-width: 655px) {
    margin-left: 0px;
  }
}

form {
  padding-left: 0;
  width: 100%;
  margin-bottom: 1em;
}

table {
  width: 100%;
}

tr {
  @media (max-width: 414px) {
    display: flex;
    flex-direction: column;
  }
}

td {
  padding: 0.8em 10px;

  @media (max-width: 414px) {
    padding: 0.8em 3px;
  }
}

input,
textarea {
  border: 1px solid $color-grey2;
  padding: 5px;
  border-radius: 2px;

  @media (max-width: 414px) {
    width: 85%;
  }
}

input {
  font-size: 16px !important;
}

.error-msg {
  position: absolute;
  font-size: 12px;
  color: red;
  padding-top: 4px;
}
</style>
