<template>
    <div class="page-content-padded" v-if="post">
        <div class="page-main page-post">
            <div class="main-info">
                <div class="post-breadcrumbs-block">
                    <router-link :to="'/user/' + app.user.path" v-if="post && app?.user">
                        <avatar :src="post.user.avatar.files.medium.url" :size="34" :center="false" />
                    </router-link>
                    <i class="fa fa-angle-right"></i>
                    <router-link :to="'/user/' + app.user.path + '/posts'" v-if="post && app?.user">
                        <span>Posts</span>
                    </router-link>
                    <i class="fa fa-angle-right"></i>
                    <span>{{ truncatedBody }}</span>
                </div>
                <!-- <router-link :to="'/user/' + app.user.path" v-if="post && app?.user">
                    <div class="post-image">
                        <div class="post-label">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i> Go back to profile
                        </div>
                    </div>
                </router-link> -->
                <div class="item-info">
                    <div v-if="post.attachment">
                        <img v-if="post.attachment" :src="post.attachment.files.medium.url" :alt="post.attachment.alt"
                            class="p-post-image" />
                    </div>
                    <div class="item-info-upper">
                        <div class="item-info-upper-left">

                            <div class="post-body">
                                {{ post.body }}
                            </div>

                            <div class="post-author">
                                <hr>
                                <div class="post-author-action-row">
                                    <router-link :to="'/user/' + post.user.path"
                                        style="display: flex;gap: 10px;align-items: center;">
                                        <avatar :src="post.user.avatar.files.medium.url" :size="26" :center="false" />
                                        <span>{{ post.user.name }}</span>
                                        <span>{{ formattedDate }}</span>
                                    </router-link>
                                    <div class="post-author-action-row">
                                        <social-sharing inline-template>
                                            <span style="display: flex;font-size: 30px;gap: 12px;margin-right: 12px;">
                                                <network network="twitter">
                                                    <i class="fab fa-twitter-square" style="cursor: pointer;"></i>
                                                </network>
                                                <network network="facebook">
                                                    <i class="fab fa-facebook" style="cursor: pointer;"></i>
                                                </network>
                                            </span>
                                        </social-sharing>
                                        <actions :actionable="post" :id="post.id" :actiontype='"post"' :isSingle="true">
                                        </actions>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="comments">
                <comments-container :commentable="post" />
            </div>
        </div>
    </div>
    <!-- <spinner style="margin: 3em auto;" v-else
             :animation-duration="1000"
             :size="80"
             color="black"
    /> -->
    <dotlottie-player v-else src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
        background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto;" loop
        autoplay></dotlottie-player>

</template>

<script>
import { mapState } from 'vuex';
import store from 'store';
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import CommentsContainer from "global/comments-container";
import Actions from 'global/actions/actions';

export default {
    data() {
        return {
            post: null,
        }
    },
    created: function () {
        this.fetchPost();
    },
    methods: {
        fetchPost() {
            axios.get('/api/post/' + this.$route.params.postid).then(response => {
                this.post = response.data;
                console.log(this.post);
            });
        }
    },
    components: {
        Spinner,
        CommentsContainer,
        Actions
    },
    computed: {
        ...mapState(['app']),
        truncatedBody() {
            return this.post.body.length > 20
                ? this.post.body.substring(0, 20) + "..."
                : this.post.body;
        },
        formattedDate() {
            return moment(this.post.created_at).format('MMM Do, YYYY');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h1 {
    font-size: 35px;
}

.page-post {
    padding: 3em;
}

.main-info {
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

.post-label {
    background-color: #e6e6e6;
    font-weight: bold;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 10px 12px;
    max-width: 168px;
}

.item-info {
    margin: 1.5rem 0;
    // flex: 1;
    display: flex;
    width: 100%;
    gap: 10px;
}

.item-info-upper {
    display: flex;
    background-color: #E6E6E6;
    padding: 10px;
    width: 100%;
}

.item-info-upper-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
}

h1 {
    margin: 0;
}

.post-body {
    font-size: 20px;
    margin: 1em 0;
    color: $color-grey2;
    line-height: 30px;
    min-height: 10vh;
}

td {
    padding: 15px 0;
    text-align: center;
}

.comments {
    margin: 3em 0;
}

.post-author {
    // display: inline-flex;
    // justify-content: flex-start;
    // gap: 5px;
    align-items: center;
    margin-top: auto;
}

.post-author h2 {
    color: $color-grey2;
}

.post-breadcrumbs-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.post-breadcrumbs-block span {
    color: #3523FB;
}

.post-author-action-row {
  display: flex;
  justify-content: space-between;
//   align-items: center;
}

.actions {
  font-size: 20px;
  gap: 4px;
}
</style>