var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.player.status.set,
          expression: "$store.state.player.status.set",
        },
      ],
      ref: "player",
      staticClass: "player",
    },
    [
      _c(
        "audio",
        {
          ref: "audio",
          attrs: { crossorigin: "anonymous" },
          on: {
            loadedmetadata: _vm.loaded,
            timeupdate: _vm.timeUpdate,
            ended: _vm.ended,
          },
        },
        [
          _vm.$store.state.player.status.set
            ? _c("source", { attrs: { src: _vm.trackUrl, type: "audio/mp3" } })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "player" }, [
        _vm.track.release && _vm.track.release.uploader
          ? _c(
              "div",
              { staticClass: "details" },
              [
                _c("img", {
                  attrs: {
                    src: _vm.track.release.image.files.thumb.url,
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "router-link",
                  { attrs: { to: `/track/${_vm.track.slug}` } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.truncatedTrackInfo) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          : _c("div", { staticClass: "details" }, [
              _vm._v("\n            " + _vm._s(_vm.track.name) + "\n        "),
            ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "progress",
            staticClass: "progress",
            on: {
              click: _vm.progressClick,
              mousedown: _vm.startProgressDrag,
              mousemove: _vm.doProgressDrag,
              mouseup: _vm.endProgressDrag,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "bar",
                style:
                  "width:" +
                  _vm.$store.state.player.status.position * 100 +
                  "%;",
              },
              [_c("span", { staticClass: "playhead" })]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "progress-time" }, [
          _vm._v(_vm._s(_vm.remainingTimeFormatted)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "center controls" }, [
          _c("span", { staticClass: "control", on: { click: _vm.skipBack } }, [
            _c("i", { staticClass: "fa fa-backward" }),
          ]),
          _vm._v("\n             \n            "),
          _c(
            "span",
            {
              staticClass: "fa-layers fa-fw",
              on: { click: _vm.togglePlayback },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.player.status.playing,
                      expression: "!$store.state.player.status.playing",
                    },
                  ],
                },
                [
                  _c("i", {
                    staticClass: "fa fw-fw fa-play",
                    staticStyle: { color: "white" },
                    attrs: { "data-fa-transform": "shrink-1" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$store.state.player.status.playing,
                      expression: "$store.state.player.status.playing",
                    },
                  ],
                },
                [
                  _c("i", {
                    staticClass: "fa fw-fw fa-pause",
                    staticStyle: { color: "white" },
                    attrs: { "data-fa-transform": "shrink-1" },
                  }),
                ]
              ),
            ]
          ),
          _vm._v("\n             \n            "),
          _c(
            "span",
            { staticClass: "control", on: { click: _vm.skipForward } },
            [_c("i", { staticClass: "fa fa-forward" })]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "volume-control" }, [
          _c("span", { staticClass: "control volume-wrap" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$store.state.player.status.muted,
                    expression: "!$store.state.player.status.muted",
                  },
                ],
                staticClass: "control",
                on: { click: _vm.toggleMute },
              },
              [
                _c("i", {
                  staticClass: "fa fa-volume-up fa-fw",
                  attrs: { "data-fa-transform": "shrink-3" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.state.player.status.muted,
                    expression: "$store.state.player.status.muted",
                  },
                ],
                staticClass: "control",
                on: { click: _vm.toggleMute },
              },
              [
                _c("i", {
                  staticClass: "fa fa-volume-off fa-fw",
                  attrs: { "data-fa-transform": "shrink-3" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "volume-bar-wrap",
                on: { mousedown: _vm.onBarMouseDown },
              },
              [
                _c("div", { ref: "bar", staticClass: "volume-bar" }, [
                  _c(
                    "div",
                    {
                      staticClass: "volume",
                      style: {
                        width: _vm.$store.state.player.status.muted
                          ? 0
                          : `${Math.trunc(_vm.volume * 100)}%`,
                      },
                    },
                    [_c("span", { staticClass: "volume-playhead" })]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("canvas", {
          ref: "canvas",
          staticStyle: { position: "relative", top: "5px" },
          attrs: { width: "255", height: "40" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "player-actions" },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTrackFollowed,
                    expression: "isTrackFollowed",
                  },
                ],
                on: { click: _vm.unfollowArtist },
              },
              [_c("i", { staticClass: "fa-solid fa-plus-circle icon-primary" })]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isTrackFollowed,
                    expression: "!isTrackFollowed",
                  },
                ],
                on: { click: _vm.followArtist },
              },
              [
                _c("i", {
                  staticClass: "fa-solid fa-check-circle icon-success",
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: `/user/${_vm.track?.release?.uploader?.path}/`,
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-users" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("like-button", {
              attrs: { likeable: _vm.track },
              on: { like: _vm.liked, unlike: _vm.unliked },
            }),
            _vm._v(" "),
            _c("share-button", { attrs: { shareable: _vm.track } }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "control",
                class: { active: _vm.isInCart(_vm.$store.state.player.track) },
                on: { click: _vm.toggleCart },
              },
              [
                _c("i", {
                  staticClass: "fa fa-cart-plus",
                  attrs: { "data-fa-transform": "shrink-3" },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "control" }, [
      _c("i", {
        staticClass: "fa fa-fw fa-circle",
        attrs: { "data-fa-transform": "grow-20" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }