<template>
    <div v-if="postBody != ''"
        :class="'wide p-item masonry-item ' + item.component + '-' + item.id + '-' + item.action_id">

        <!-- <div class="p-item-image"> -->
        <div class="p-item-head">
            <router-link :to="getRouterObject(item)">
                <avatar :size="60" :src="item.user.avatar.files.thumb.url" />
            </router-link>
            <div class="p-item-detail">
                <div class="p-item-title">
                    <span>{{ item.user.name }}</span>
                    <span class="p-item-date-time">Posted {{ formatDate(item.created_at) }}</span>
                </div>
            </div>
        </div>

        <!-- </div> -->

        <div class="p-item-main">
            <div class="p-post-text">
                <img v-if="item.attachment" :src="item.attachment.files.medium.url" :alt="item.attachment.alt"
                    class="p-post-image" />
                <!-- <router-link
                :to="getRouterObject(item)"> -->
                <div class="p-item-body">
                    <textarea v-if="isEditing" v-model="editedPostBody" class="form-control post-editable-input"
                        rows="2" cols="50"></textarea>
                </div>
                <p v-if="postBody != '' && !isEditing" class="p-item-body">{{ postBody }}</p>
                <!-- </router-link> -->
            </div>
            <div class="p-item-meta">
                <div class="p-item-counts">
                    <span>{{ item.comments_count }} comments</span>
                    <span>{{ item.likes_count }} Likes</span>
                    <span>{{ item.shares_count }} shares</span>
                </div>
                <actions v-on:delete-action="deletedItem" :actionable="item" :id="item.action_id" :isFeed="true"
                    @edit-post="enableEditMode" v-if="!isEditing">
                </actions>
                <div class="p-item-detail post-editable-actions" v-if="isEditing">
                    <ph-button @click.native="savePost" size="small" :loading="submitting"
                        color="primary-outline">Submit</ph-button>
                    <ph-button @click.native="cancelEdit" size="small" color="danger-outline">Cancel</ph-button>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import Actions from 'global/actions/actions';
import ActionMenu from 'global/actions/action-menu';
import Avatar from 'global/avatar';
import { SocialEvents } from "../../../event-bus";
import moment from 'moment';
import { mapState } from 'vuex';
import store from 'store';


export default {
    props: { item: Object, selectedCategory: String },
    created: function () {
        SocialEvents.$on('delete-action', this.deletedItem)
    },
    data() {
        return {
            isDeleted: (this.item.deleted == true) ? true : false,
            isEditing: false,
            editedPostBody: '',
            submitting: false,
        }
    },
    computed: {
        ...mapState([
            'app',
        ]),
        postBody() {
            if (this.item.body) {
                return this.item.body;
            } else {
                return '';
            }
        }
    },
    methods: {
        deletedItem() {
            var action_id = localStorage.getItem('last_deleted_action_id');
            if (this.item.action_id == action_id) {
                this.item.deleted = true;
                this.isDeleted = true;
                this.item.body = '';
            }

        },
        formatDate(date) {
            return moment(date).format('MMMM Do [at] h:mm A'); // January 12th at 11:02 AM
        },
        enableEditMode() {
            this.isEditing = true;
            this.editedPostBody = this.item.body;
        },
        cancelEdit() {
            this.isEditing = false;
        },
        savePost() {
            if (this.editedPostBody.trim()) {
                let data = new FormData();
                data.append("body", this.editedPostBody.trim());

                this.submitting = true;
                axios
                    .post(`/api/user/posts/${this.item.id}/update`, data)
                    .then((response) => {
                        this.$modal.hide("modal-update-post");
                        this.$notify({
                            group: "main",
                            type: "success",
                            title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                            duration: 1500,
                        });
                    })
                    .finally(() => {
                        this.submitting = false;
                        this.isEditing = false;
                        this.item.body = this.editedPostBody.trim();
                    });
            }
        },
        fetchFeed() {
            store.dispatch('app/fetchFeed');
        }
    },
    components: {
        Actions,
        ActionMenu,
        Avatar,
    }
};
</script>

<style lang="scss" scoped>
.p-item {
    align-items: flex-start;
    flex-direction: column;
    padding: 8px 14px;
    background-color: #e6e6e6
}

.p-item-head {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}

.p-post-text {
    flex: 1;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 2em;
}

.p-item-body {
    font-size: 16px;
    margin: 6px;
    color: black;
}

.p-item-title {
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-size: 19px;
    color: #366efc;
}

.p-item-date-time {
    color: #aea7a7;
    font-size: 10px;
}

.p-item-main {
    height: 180px;
    justify-content: flex-start;
}

.p-item-counts {
    display: flex;
    gap: 8px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.p-item-counts span:not(:last-child)::after {
    content: " | ";
    margin-left: 5px;
}

.p-post-image {
    height: 88px;
    max-width: 100px;
    width: auto;
    margin-bottom: 1em;
}

.p-item-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-editable-input {
    width: 100%;
    border: 1px solid #ffff;
    border-radius: 5px;
}

.post-editable-actions {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>
