<template>
    <div>
        <cart />
        <checkout />
        <upload />
        <auth />
        <change-avatar />
        <change-banner />
        <report />
        <share />
        <comment />
        <faq />
        <create-event />
        <update-event />
        <upload-video />
        <video-player />
        <create-merch />
        <update-merch />
        <update-post />
        <featured-dates-payment />
        <mailing-list />
        <delete-confirm />
        <edit-comment />
    </div>
</template>

<script>
import Cart from './cart/cart'
import Checkout from './cart/checkout'
import Upload from './upload'
import Auth from './auth'
import ChangeAvatar from './change-avatar'
import ChangeBanner from './change-banner'
import Report from './report'
import Share from './share'
import Comment from './comment'
import Faq from './faq'
import CreateEvent from './create-event'
import UploadVideo from './upload-video/upload-video'
import VideoPlayer from './video-player'
import CreateMerch from './create-merch'
import UpdateMerch from './update-merch'
import UpdatePost from './update-post'
import FeaturedDatesPayment from './featured-dates-payment'
import MailingList from './mailing-list'
import DeleteConfirm from './delete-confirm'
import EditComment from './edit-comment'
import UpdateEvent from './update-event';


export default {
    data() {
        return {}
    },
    created: function () {

    },
    components: {
        MailingList,
        FeaturedDatesPayment,
        Cart,
        Checkout,
        Upload,
        Auth,
        ChangeAvatar,
        ChangeBanner,
        Report,
        Share,
        Comment,
        Faq,
        CreateEvent,
        UpdateEvent,
        UploadVideo,
        VideoPlayer,
        CreateMerch,
        UpdateMerch,
        UpdatePost,
        DeleteConfirm,
        EditComment
    },
}
</script>

<style lang="scss">
@import "~styles/helpers/_variables.scss";

.v--modal {
    margin: 20px 0;
    padding: 3em;

    @media(max-width: 767px) {
        padding: 2em;
        left: 0 !important;
    }
}

.modal {
    width: 100%;
    height: 100%;

    font-family: $font-comfortaa;

    // .modal-logo {
    //     padding-top: 3em;
    // }
}

.modal-header {
    // position: relative;

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.modal-content {
    padding: 2em;

    @media(max-width: 400px) {
        padding: 1em;
    }

    &.full-width {
        padding: 2em 0;
    }
}

.content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.content-section h3 {
    font-size: 20px;
    line-height: 1.5rem;
    text-align: center;
}

.content-section p {
    font-size: 14px;
    margin: 1em 0;
    text-align: center;
    line-height: 18px;
}
</style>
