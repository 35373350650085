var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-to-artist-downgrade",
        width: "500px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-downgrade" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.handleClose()
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _vm.downgradeConfirm
            ? _c("div", { staticClass: "timePeriodBlock" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(this.subscriptionLeftPeriod()) +
                    "\n            "
                ),
              ])
            : _c("div", { staticClass: "content-section" }, [
                _c("h2", [_vm._v("Downgrade Account")]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    "Are you sure you want to downgrade to an Artist account?"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-button" },
                  [
                    _c(
                      "ph-button",
                      {
                        attrs: {
                          size: "large",
                          loading: _vm.submitting,
                          color: "danger-outline",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.downgradeConfirmAccount.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Downgrade")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Your account will remain the same until your paid period expires, you will then automatically be\n                    downgraded, any current membership benefits will be lost."
                  ),
                ]),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }