<template>
    <div class="video video-clear" v-if="video.title && video.asset && video.asset.files.video_thumbnail">
        <!-- <div class="delete-video" v-if="app.user && app.user.id === video.user_id"
            @click.prevent="$modal.show('modal-delete-confirm', { deleteable: video })">
            <i class="fa fa-trash"></i>
        </div> -->

        <actions :actionable="video" :id="id" :actiontype='"video"' @edit-video="enableEditMode" v-if="!isEditing"
            style="justify-content: flex-end !important;">
        </actions>

        <div class="p-video-main">
            <div class="p-video-text">
                <div class="p-video-detail" v-if="isEditing">
                    <input type="text" name="" v-model="editedVideoTitle" class="form-control video-editable-input" id="">
                    <textarea v-model="editedVideoBody" class="form-control video-editable-input" rows="5"
                        cols="60"></textarea>
                </div>
                <div class="p-video-detail" v-else>
                    <h5>{{ video.title }}</h5>
                    <p>{{ video.description }}</p>
                </div>

                <div class="p-video-detail video-editable-actions" v-if="isEditing">
                    <ph-button @click.native="savePost" size="small" :loading="submitting"
                        color="primary-outline">Submit</ph-button>
                    <ph-button @click.native="cancelEdit" size="small" color="danger-outline">Cancel</ph-button>
                </div>
            </div>

            <video controls width="100%" height="300" :id="'video' + video.id"
                :poster="video.asset.files.video_thumbnail.url">
                <source :src="video.asset.files.original.url" :type="video.asset.files.original.mime" />
            </video>
        </div>


    </div>
</template>

<script>
import 'mediaelement/full';
import { mapState } from "vuex";
import Actions from 'global/actions/actions';
import { UserEvents, SocialEvents } from "events";


export default {
    props: {
        video: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            moment: window.moment,
            isEditing: false,
            editedVideoTitle: '',
            editedVideoBody: '',
            submitting: false,
        }
    },
    computed: {
        ...mapState([
            'app',
        ]),
    },

    mounted: function () {
        new MediaElementPlayer(document.getElementById('video' + this.video.id), {
            success: function () {

            }
        });
    },
    methods: {
        enableEditMode() {
            this.isEditing = true;
            this.editedVideoTitle = this.video.title;
            this.editedVideoBody = this.video.description;
        },
        cancelEdit() {
            this.isEditing = false;
        },
        savePost() {
            if (this.editedVideoBody.trim() && this.editedVideoTitle.trim()) {
                let data = new FormData();
                data.append("title", this.editedVideoTitle.trim());
                data.append("body", this.editedVideoBody.trim());

                this.submitting = true;
                axios
                    .post(`/api/video/${this.video.id}/update`, data)
                    .then((response) => {
                        this.$notify({
                            group: "main",
                            type: "success",
                            title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                            duration: 1500,
                        });
                    })
                    .finally(() => {
                        this.submitting = false;
                        this.isEditing = false;
                        UserEvents.$emit("video-added");
                        SocialEvents.$emit("activity-update");
                    });
            }
        },
    },
    components: {
        Actions
    }
}
</script>

<style lang="scss" scoped>
.p-video-text {
    flex: 1;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2em;
}

.p-video-main {
    justify-content: flex-start;
}

.p-post-iframe {
    margin-top: 8px;
}

.delete-video {
    float: right;
    margin-top: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.video-clear {
    clear: both;
    margin-bottom: 20px;
}

.video-editable-input {
    width: 100%;
    border: 1px solid #E6E6E6;
    background-color: #E6E6E6;
    padding: 8px;
    margin: 8px 0px;
    border-radius: 5px;
}

.video-editable-actions{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>
