<template>
    <div>
        <!-- <spinner style="margin: 3em auto;" :animation-duration="1000" :size="60" color="black" v-show="loadingEvents" /> -->
        <dotlottie-player src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json" background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto" loop autoplay v-show="loadingEvents"></dotlottie-player>
        <div v-if="events.length">
            <item v-for="event in events" :item="event" :key="event.id" />
        </div>
        <div v-if="!events.length && !loadingEvents" class="not-found">
            <span class="not-found" v-if="!isShow">
                Sorry, this user hasn’t uploaded any events!
            </span>
            <ph-button size="medium" style="display: block; text-align: center;" @click.native="showEventCreateModal()"
                v-if="isShow && isPro">Add Event</ph-button>
        </div>
    </div>
</template>

<script>
import ProfileMixin from '../profile-mixin';
import { HalfCircleSpinner as Spinner } from 'epic-spinners';
import Item from 'global/items/item';
import { ModalEvents } from '../../../../../event-bus';
import { mapState } from "vuex";

export default {
    data() {
        return {
            loadingEvents: false,
            events: [],
        }
    },
    computed: {
        isPro: function () {
            return (this.app.user.account_type === 'pro' || this.app.user.account_type === 'admin')
        },
        isShow: function () {
            return (this.app.user.id === this.user.id)
        },
        ...mapState([
            'app'
        ])
    },
    created: function () {
        this.fetchEvents();
        ModalEvents.$on('event-created', this.fetchEvents)
    },
    methods: {
        showEventCreateModal() {
            this.$modal.show('modal-create-event', { user: this.user });
        },
        fetchEvents() {
            this.loadingEvents = true;
            axios.get('/api/user/' + this.user.id + '/events').then(response => {
                this.events = response.data;
            }).finally(() => {
                this.loadingEvents = false;
            });
        }
    },
    mixins: [
        ProfileMixin
    ],
    components: {
        Item,
        Spinner
    }
}
</script>

<style lang="scss" scoped>
.not-found {
    text-align: center;
    margin-top: 10px;
    display: block;
}
</style>
