var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.postBody != ""
    ? _c(
        "div",
        {
          class:
            "wide p-item masonry-item " +
            _vm.item.component +
            "-" +
            _vm.item.id +
            "-" +
            _vm.item.action_id,
        },
        [
          _c(
            "div",
            { staticClass: "p-item-head" },
            [
              _c(
                "router-link",
                { attrs: { to: _vm.getRouterObject(_vm.item) } },
                [
                  _c("avatar", {
                    attrs: {
                      size: 60,
                      src: _vm.item.user.avatar.files.thumb.url,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "p-item-detail" }, [
                _c("div", { staticClass: "p-item-title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.item.user.name))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "p-item-date-time" }, [
                    _vm._v(
                      "Posted " + _vm._s(_vm.formatDate(_vm.item.created_at))
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-main" }, [
            _c("div", { staticClass: "p-post-text" }, [
              _vm.item.attachment
                ? _c("img", {
                    staticClass: "p-post-image",
                    attrs: {
                      src: _vm.item.attachment.files.medium.url,
                      alt: _vm.item.attachment.alt,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "p-item-body" }, [
                _vm.isEditing
                  ? _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedPostBody,
                          expression: "editedPostBody",
                        },
                      ],
                      staticClass: "form-control post-editable-input",
                      attrs: { rows: "2", cols: "50" },
                      domProps: { value: _vm.editedPostBody },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.editedPostBody = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.postBody != "" && !_vm.isEditing
                ? _c("p", { staticClass: "p-item-body" }, [
                    _vm._v(_vm._s(_vm.postBody)),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-item-meta" },
              [
                _c("div", { staticClass: "p-item-counts" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.comments_count) + " comments"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.item.likes_count) + " Likes")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.shares_count) + " shares"),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.isEditing
                  ? _c("actions", {
                      attrs: {
                        actionable: _vm.item,
                        id: _vm.item.action_id,
                        isFeed: true,
                      },
                      on: {
                        "delete-action": _vm.deletedItem,
                        "edit-post": _vm.enableEditMode,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isEditing
                  ? _c(
                      "div",
                      { staticClass: "p-item-detail post-editable-actions" },
                      [
                        _c(
                          "ph-button",
                          {
                            attrs: {
                              size: "small",
                              loading: _vm.submitting,
                              color: "primary-outline",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.savePost.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Submit")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ph-button",
                          {
                            attrs: { size: "small", color: "danger-outline" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.cancelEdit.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }