<template>
    <div>
        <div class="search-breadcrumb" v-show="search.fromSearch"><span @click="handleSearchActions()">Search</span> > <span>{{track?.name}}</span></div>
        <track-release v-if="track && track?.status === 'approved'" :item="track" />
        <div v-if="track && track?.status !== 'approved' && !track?.freezed" class="center-content">
            This track has not been approved yet.
        </div>
        <div v-if="track && track?.freezed" class="center-content">
            This track has been frozen.
        </div>
        <!-- <spinner style="margin: 3em auto;" v-if="!track" :animation-duration="1000" :size="80" color="black" /> -->
        <dotlottie-player v-if="!track" src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
            background="transparent" speed="1" style="width: 150px; height: 150px;margin: 3em auto;" loop
            autoplay></dotlottie-player>
    </div>
</template>

<script>
import TrackRelease from './track-release';
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import { mapState, mapMutations } from "vuex";

export default {
    data() {
        return {
            track: null,
        }
    },
    computed: {
        ...mapState(["app", "search"]),
    },
    created: function () {
        this.fetchTrack();
    },
    watch: {
        '$route.params.trackid': function (newTrackId) {
            this.track = null;
            this.fetchTrack();
        }
    },
    methods: {
        ...mapMutations({
            setFromSearch: 'search/setFromSearch',
            setToggleSearch: 'search/toggleSearch'
        }),
        fetchTrack() {
            axios.get('/api/track/' + this.$route.params.trackid).then(response => {
                this.track = response.data;
            });
        },
        handleSearchActions(){
            console.log(this.search.searchTerm);
            this.setToggleSearch(true);
        }
    },
    components: {
        TrackRelease,
        Spinner,
    }
}
</script>

<style lang="scss" scoped>
.center-content {
    display: flex;
    width: 100vw;
    height: 50vh;
    justify-content: center;
    align-items: center;
}
</style>
