var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.search.fromSearch,
              expression: "search.fromSearch",
            },
          ],
          staticClass: "search-breadcrumb",
        },
        [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.handleSearchActions()
                },
              },
            },
            [_vm._v("Search")]
          ),
          _vm._v(" >\n        "),
          _c("span", [_vm._v(_vm._s(_vm.release?.name))]),
        ]
      ),
      _vm._v(" "),
      (_vm.release &&
        _vm.release?.status === "live" &&
        !_vm.release?.freezed) ||
      (_vm.release &&
        _vm.app.user.all_permissions.includes("view admin dashboard") &&
        !_vm.release?.freezed)
        ? _c("track-release", { attrs: { item: _vm.release } })
        : _vm._e(),
      _vm._v(" "),
      _vm.release &&
      _vm.release?.status !== "live" &&
      !_vm.app.user.all_permissions.includes("view admin dashboard") &&
      !_vm.release?.freezed
        ? _c("div", { staticClass: "center-content" }, [
            _vm._v("\n        This release has not been approved yet.\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.release && _vm.release?.freezed
        ? _c("div", { staticClass: "center-content" }, [
            _vm._v("\n        This release has been frozen.\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.release
        ? _c("dotlottie-player", {
            staticStyle: {
              width: "150px",
              height: "150px",
              margin: "3em auto",
            },
            attrs: {
              src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
              background: "transparent",
              speed: "1",
              loop: "",
              autoplay: "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }