var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("cart"),
      _vm._v(" "),
      _c("checkout"),
      _vm._v(" "),
      _c("upload"),
      _vm._v(" "),
      _c("auth"),
      _vm._v(" "),
      _c("change-avatar"),
      _vm._v(" "),
      _c("change-banner"),
      _vm._v(" "),
      _c("report"),
      _vm._v(" "),
      _c("share"),
      _vm._v(" "),
      _c("comment"),
      _vm._v(" "),
      _c("faq"),
      _vm._v(" "),
      _c("create-event"),
      _vm._v(" "),
      _c("update-event"),
      _vm._v(" "),
      _c("upload-video"),
      _vm._v(" "),
      _c("video-player"),
      _vm._v(" "),
      _c("create-merch"),
      _vm._v(" "),
      _c("update-merch"),
      _vm._v(" "),
      _c("update-post"),
      _vm._v(" "),
      _c("featured-dates-payment"),
      _vm._v(" "),
      _c("mailing-list"),
      _vm._v(" "),
      _c("delete-confirm"),
      _vm._v(" "),
      _c("edit-comment"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }