<template>
    <ph-panel id="notifications">
        <h2>Notifications</h2>
        <!-- <hr /> -->
        <div v-if="loaded">
            <div class="options-container">
                <div class="options-column">
                    <div class="col-header">
                        <div class="notify-heading">
                            <span>Activities</span>
                        </div>
                        <div class="col-icons">
                            <div class="notification-icon">
                                <i class="fa fa-bell"></i>
                                <span class="notification-count">1</span>
                            </div>
                            <!-- <img src="/img/notification_icon.png" alt=""> -->
                            <img src="/img/Email_notification_icon.png" alt="">
                        </div>
                    </div>
                    <ul>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your release has been shared
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_shared_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_shared_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your release has been purchased
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_purchased_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_purchased_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your release has been favourited
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_fav_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_fav_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your release has been commented on
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_commented_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.release_commented_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your track has been shared
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_shared_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_shared_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your track has been purchased
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_purchased_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_purchased_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your track has been favourited
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_fav_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_fav_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li
                            v-if="app.user.roles[0].name == 'pro' || app.user.roles[0].name == 'artist' || app.user.roles[0].name == 'admin'">
                            <label class="notify-title">
                                Your track has been commented on
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_commented_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.track_commented_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <!-- <li>
                            <label class="notify-title">
                                Your profile has been favourited
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_profile_fav_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_profile_fav_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li> -->
                        <li>
                            <label class="notify-title">
                                Your post has been shared
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_share_on_mine_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_share_on_mine_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                Your post has been favourited
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_post_fav_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_post_fav_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                Your post has been commented
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_comment_on_mine_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_comment_on_mine_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                Your profile has been shared
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.profile_shared_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.profile_shared_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>


                        <!-- <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_follower_on_me_email" />
                                New Follower
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_share_on_mine_email" />
                                Share my post
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_post_from_followee_email" />
                                New post from someone I follow
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_like_on_mine_email" />
                                New like on my post
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_comment_on_mine_email" />
                                New comment on my post
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_message_email" />
                                New message
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li> -->
                    </ul>
                </div>
                <div class="border-line"></div>
                <div class="options-column">
                    <div class="col-header">
                        <div class="notify-heading">
                            <span>Social</span>
                        </div>
                        <div class="col-icons">
                            <div class="notification-icon">
                                <i class="fa fa-bell"></i>
                                <span class="notification-count">1</span>
                            </div>
                            <!-- <img src="/img/notification_icon.png" alt=""> -->
                            <img src="/img/Email_notification_icon.png" alt="">
                        </div>
                    </div>
                    <ul>
                        <li>
                            <label class="notify-title">
                                New Messages
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    &nbsp;
                                    <!-- <input type="checkbox" v-model="options.activity_message_notify">
                                    <span class="slider round"></span> -->
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_message_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                New Followers
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_follower_on_me_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_follower_on_me_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                User you follow has posted
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_post_from_followee_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_post_from_followee_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                User you follow has posted a new event
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_event_from_followee_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_event_from_followee_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                User who you follow has released new music
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_released_new_music_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_released_new_music_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                User who you follow has released new merch
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_released_new_merch_notify">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.activity_released_new_merch_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <!-- <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_follower_on_me_email" />
                                New Follower
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.activity_message_email" />
                                New message
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li> -->
                    </ul>
                </div>
                <div class="border-line"></div>
                <div class="options-column">
                    <div class="col-header">
                        <div class="notify-heading">
                            <span>Phase</span>
                        </div>
                        <div class="col-icons">
                            <div class="notification-icon">
                                <i class="fa fa-bell"></i>
                                <span class="notification-count">1</span>
                            </div>
                            <!-- <img src="/img/notification_icon.png" alt=""> -->
                            <img src="/img/Email_notification_icon.png" alt="">
                        </div>
                    </div>
                    <ul>
                        <li>
                            <label class="notify-title">
                                Phase News
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    &nbsp;
                                    <!-- <input type="checkbox" v-model="options.phase_new_features_notify">
                                    <span class="slider round"></span> -->
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.phase_new_features_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                Music News
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    &nbsp;
                                    <!-- <input type="checkbox" v-model="options.phase_music_news_notify">
                                    <span class="slider round"></span> -->
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.phase_music_news_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                Tip & Offers
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    &nbsp;
                                    <!-- <input type="checkbox" v-model="options.phase_tips_offers_notify">
                                    <span class="slider round"></span> -->
                                </label>
                                <label class="switch">
                                    <input type="checkbox" v-model="options.phase_tips_offers_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <!-- <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.phase_new_features_email" />
                                New Phase features
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.phase_surveys_feedback_email" />
                                Surveys and feedback
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.phase_tips_offers_email" />
                                Tips & Offers
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label class="notify-title">
                                <input type="checkbox" v-model="options.phase_newsletter_email" />
                                Phase newsletter
                            </label>
                            <div class="notify-options">
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
            <ph-button size="medium" class="mt-2" @click.native="save" :loading="submitting">Save</ph-button>
        </div>
        <!-- <spinner style="margin: 3em auto;"

                 v-else
                 :animation-duration="1000"
                 :size="60"
                 :color="'black'"
        /> -->
        <div class="phase-loading" v-else>
            <dotlottie-player src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
                background="transparent" speed="1" style="width: 150px; height: 150px;" loop
                autoplay></dotlottie-player>
            <!-- <img src="/img/phase-loading.gif" alt="" srcset=""> -->
        </div>
    </ph-panel>
</template>

<script>
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import { mapState } from "vuex";

export default {
    data() {
        return {
            loaded: false,
            submitting: false,
            options: {
                release_shared_email: false,
                release_purchased_email: false,
                release_commented_email: false,
                release_fav_email: false,
                track_shared_email: false,
                track_purchased_email: false,
                track_commented_email: false,
                track_fav_email: false,
                activity_follower_on_me_email: false,
                activity_share_on_mine_email: false,
                activity_post_from_followee_email: false,
                activity_event_from_followee_email: false,
                activity_profile_fav_email: false,
                activity_post_fav_email: false,
                activity_like_on_mine_email: false,
                activity_comment_on_mine_email: false,
                activity_message_email: false,
                activity_post_favourite_email: false,
                phase_music_news_email: false,
                phase_new_features_email: false,
                phase_surveys_feedback_email: false,
                phase_tips_offers_email: false,
                profile_shared_email: false,
                activity_event_from_followee_email: false,
                activity_released_new_music_email: false,
                // phase_newsletter_email: false,

                release_shared_notify: false,
                release_purchased_notify: false,
                release_commented_notify: false,
                release_fav_notify: false,
                track_shared_notify: false,
                track_purchased_notify: false,
                track_commented_notify: false,
                track_fav_notify: false,
                activity_follower_on_me_notify: false,
                activity_event_from_followee_notify: false,
                activity_post_fav_notify: false,
                activity_profile_fav_notify: false,
                activity_share_on_mine_notify: false,
                activity_post_from_followee_notify: false,
                activity_like_on_mine_notify: false,
                activity_comment_on_mine_notify: false,
                activity_message_notify: false,
                activity_post_favourite_notify: false,
                phase_music_news_notify: false,
                phase_new_features_notify: false,
                phase_surveys_feedback_notify: false,
                phase_tips_offers_notify: false,
                profile_shared_notify: false,
                activity_event_from_followee_notify: false,
                activity_released_new_music_notify: false,

                // phase_newsletter_notify: false,
            }
        }
    },
    computed: {
        ...mapState(["app"]),
    },
    mounted: function () {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loaded = false;
            axios.get('/api/account/notifications').then(response => {
                for (var property in response.data) {
                    response.data[property] = !!response.data[property];
                }
                this.options = response.data;
            }).finally(() => {
                this.loaded = true;
            });
        },
        save() {
            this.submitting = true;
            axios.post('/api/account/notifications', this.options).then(response => {
                this.$notify({
                    group: 'main',
                    type: 'success',
                    title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                    duration: 1500,
                });
            }).catch(() => {
                this.$notify({
                    group: 'main',
                    type: 'error',
                    title: '<div class="notify-text">Error saving notification settings. Please try again later</div>',
                });
            }).finally(() => {
                this.submitting = false;
            });
        }
    },
    components: {
        Spinner
    }
}
</script>

<style lang="scss" scoped>
.border-line {
    width: 1px;
    background: #30f;
    margin-top: -10px;
    margin-left: 12px;
    margin-right: 12px;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 1px solid #30f;
    padding-bottom: 5px;
}

.options-container {
    // margin: 15px 0;
    overflow: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

}

.col-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 2px solid #3300ff;
    padding-top: 8px;
}

.col-icons {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.col-icons .fa-bell {
    font-size: 30px !important;
}

.notification-icon {
    position: relative;
}

.notification-count {
    position: absolute;
    top: 0px;
    right: -8px;
    background: #3300ff;
    color: #fff;
    border-radius: 30px;
    padding: 4px 8px;
    font-size: 10px;
    font-weight: bold;
}


.notify-options {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.col-icons img {
    width: 40px;
}

.col-icons img:first-child {
    padding-bottom: 13px;
}

.notify-heading {
    width: 75%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
}

.notify-heading span {
    border-bottom: 2px solid #30f;
}

.options-column {
    width: 33%;
    background-color: #fff;
}

.options-column:nth-child(-n+2) {
    // margin-right: 15px;
    // border-right: 2px solid #3300ff;
}

li {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
}

.notify-title {
    padding-left: 10px;
}


.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 20px;
}

.switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #121212;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 0px;
    bottom: 2.5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input[type="checkbox"]:checked+.slider {
    background-color: #2196F3;
}

input[type="checkbox"]:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input[type="checkbox"]:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
