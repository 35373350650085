var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ph-panel",
    { attrs: { id: "manage" } },
    [
      _c(
        "div",
        { staticClass: "col-header flex justify-between align-item-center" },
        [
          _c("h2", [_vm._v("Account")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "ph-button",
                {
                  staticClass: "deactive-btn",
                  attrs: { size: "deactive" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.deactivateAccount.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n                Deactivate\n            ")]
              ),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  staticClass: "del-btn",
                  staticStyle: { "margin-bottom": "5px" },
                  attrs: { size: "delete" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.deleteAccount.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n                Delete\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "account-setting" }, [
        _c("div", { staticClass: "membership" }, [
          _c("div", { staticClass: "title-block" }, [
            _c("h4", [_vm._v("Membership")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "status-setting" }, [
            _c(
              "div",
              { staticClass: "account-status" },
              [
                _c("div", { staticClass: "account-status-text" }, [
                  _vm.app.user.roles[0].name === "standard"
                    ? _c("span", [_c("circle-tick-icon")], 1)
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name !== "standard"
                    ? _c("span", [_c("circle-empty-icon")], 1)
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v("Standard")]),
                ]),
                _vm._v(" "),
                _vm.app.user.roles[0].name === "artist" ||
                _vm.app.user.roles[0].name === "pro"
                  ? _c(
                      "ph-button",
                      {
                        staticStyle: { "margin-bottom": "5px" },
                        attrs: { size: "deactive", loading: _vm.downgrading },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.downgradeAccount.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Downgrade")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "account-status" }, [
              _c("div", { staticClass: "account-status-text" }, [
                _vm.app.user.roles[0]?.name === "artist" &&
                _vm.app.user.approved_at
                  ? _c("span", [_c("circle-tick-icon")], 1)
                  : _vm.app.user.roles[0]?.name === "artist" &&
                    _vm.app.user.account_type === "standard" &&
                    !_vm.app.user.approved_at
                  ? _c(
                      "span",
                      [
                        _c("circle-exclamation-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Under review",
                              expression: "'Under review'",
                            },
                          ],
                        }),
                      ],
                      1
                    )
                  : _vm.asForType === "standardToArtist" &&
                    !_vm.app.user.approved_at
                  ? _c(
                      "span",
                      [
                        _c("circle-exclamation-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Under review",
                              expression: "'Under review'",
                            },
                          ],
                        }),
                      ],
                      1
                    )
                  : _c("span", [_c("circle-empty-icon")], 1),
                _vm._v(" "),
                _c("span", [_vm._v("Artist")]),
              ]),
              _vm._v(" "),
              (_vm.asForType &&
                _vm.asForType != "standardToArtist" &&
                _vm.asForType != "standardToPro") ||
              _vm.app.user.approved_at
                ? _c(
                    "div",
                    [
                      _vm.app.user.roles[0].name === "pro" &&
                      _vm.subscriptionsLoaded &&
                      _vm.subscriptions[0]?.stripe_status !=
                        "pending-cancellation"
                        ? _c(
                            "ph-button",
                            {
                              staticStyle: { "margin-bottom": "5px" },
                              attrs: {
                                size: "deactive",
                                loading: _vm.downgrading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.downgradeToArtist.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Downgrade")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.app.user.roles[0].name == "standard"
                        ? _c(
                            "ph-button",
                            {
                              staticClass: "trial-button",
                              attrs: {
                                color: "primary-outline",
                                loading: _vm.upgrading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.upgradeAccount.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Upgrade\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "account-status" }, [
              _c("div", { staticClass: "account-status-text" }, [
                _vm.app.user.roles[0].name === "pro" &&
                _vm.app.user.account_type === "pro" &&
                _vm.app.user.approved_at &&
                (_vm.subscriptions[0]?.stripe_status == "active" ||
                  _vm.subscriptions[0]?.stripe_status == "trialing")
                  ? _c("span", [_c("circle-tick-icon")], 1)
                  : (_vm.app.user.roles[0].name == "pro" &&
                      _vm.app.user.account_type === "standard" &&
                      !_vm.app.user.approved_at) ||
                    (_vm.asForType == "standardToPro" &&
                      !_vm.app.user.approved_at)
                  ? _c(
                      "span",
                      [
                        _c("circle-exclamation-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Under review",
                              expression: "'Under review'",
                            },
                          ],
                        }),
                      ],
                      1
                    )
                  : _vm.app.user.roles[0].name == "pro" &&
                    _vm.app.user.approved_at &&
                    _vm.subscriptions[0]?.stripe_status ==
                      "pending-cancellation"
                  ? _c(
                      "span",
                      [
                        _c("circle-exclamation-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Cancelled",
                              expression: "'Cancelled'",
                            },
                          ],
                        }),
                      ],
                      1
                    )
                  : _c("span", [_c("circle-empty-icon")], 1),
                _vm._v(" "),
                _c("span", [
                  _vm._v("Artist PRO "),
                  _c("span", { staticClass: "pro-badge" }, [_vm._v("PRO")]),
                ]),
              ]),
              _vm._v(" "),
              (_vm.asForType &&
                _vm.asForType != "standardToPro" &&
                _vm.asForType != "standardToArtist") ||
              _vm.app.user.approved_at
                ? _c(
                    "div",
                    [
                      _vm.app.user.roles[0].name != "pro" &&
                      _vm.subscriptionsLoaded
                        ? _c(
                            "ph-button",
                            {
                              staticClass: "trial-button",
                              attrs: {
                                color: "gradient-upgrade",
                                loading: _vm.upgradingPro,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.upgradeAccountToPro()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.app.user.roles[0].name == "artist" &&
                                      _vm.subscriptions.length == 0
                                      ? "Start Trial"
                                      : "Upgrade"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "border-line" }),
        _vm._v(" "),
        _c("div", { staticClass: "membership" }, [
          _c("div", { staticClass: "title-block" }, [
            _c("h4", [_vm._v("Membership Payment Method")]),
          ]),
          _vm._v(" "),
          _vm.subscriptionsLoaded && _vm.subscriptions.length == 0
            ? _c("div", { staticClass: "no-subscription-block" }, [
                _c(
                  "p",
                  {
                    staticClass: "no-subscription-label",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _vm._v(
                      "Not subscribed, free 30 days trail\n                    available."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.subscriptions.length > 0 && _vm.membershipPaymentMethod
            ? _c(
                "div",
                [
                  _c("membership-payment-method-view", {
                    attrs: { method: _vm.membershipPaymentMethod },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    _vm._l(_vm.subscriptions, function (subscription) {
                      return _vm.plansLoaded && _vm.subscriptionsLoaded
                        ? _c("subscription-plan", {
                            key: subscription.id,
                            attrs: {
                              subscription: subscription,
                              plan:
                                subscription.stripe_plan == "Artist Pro EU"
                                  ? _vm.plans[0]
                                  : _vm.plans[1],
                              subStatus: _vm.stripeSubscriptionStatus,
                            },
                          })
                        : _vm._e()
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.subscriptionsLoaded
            ? _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("dotlottie-player", {
                    staticStyle: { width: "150px", height: "150px" },
                    attrs: {
                      src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                      background: "transparent",
                      speed: "1",
                      loop: "",
                      autoplay: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("delete-modal"),
      _vm._v(" "),
      _c("deactivate-modal"),
      _vm._v(" "),
      _c("downgrade-modal"),
      _vm._v(" "),
      _c("downgrade-to-artist-modal"),
      _vm._v(" "),
      _c(
        "modal",
        {
          staticStyle: { padding: "10px 35px", display: "table-cell" },
          attrs: {
            name: "modal-account-reg-form",
            width: "80%",
            height: "auto",
            scrollable: "",
          },
          on: { "before-open": _vm.beforeOpen, closed: _vm.closed },
        },
        [
          _c("div", { staticClass: "modal modal-account-reg-form" }, [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _c("close-icon", {
                  staticClass: "float-right",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeManageModals()
                    },
                  },
                }),
                _vm._v(" "),
                _c("logo", {
                  staticClass: "modal-logo centered-block",
                  staticStyle: { width: "185px" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.submitted
              ? _c(
                  "div",
                  {
                    staticClass: "modal-content full-width",
                    staticStyle: { padding: "10px 35px" },
                  },
                  [
                    _vm.step === 2
                      ? _c("connect-details", {
                          attrs: { user: _vm.app.user },
                          on: {
                            "next-step": function ($event) {
                              _vm.step++
                            },
                            finished: function ($event) {
                              _vm.submitted = true
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.step === 3
                      ? _c("verification-details", {
                          attrs: { user: _vm.app.user },
                          on: { finished: _vm.upgradeAccount },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }