<template>
  <div class="page-content-padded">

    <div class="layout-wave-one">
      <img src="/img/layered-waves2.svg" alt="" srcset="">
    </div>

    <!-- Overlay for Notify -->
    <overlay-notify :is-visible="isOverlayVisible"
      :duration="$route.query['email_verified'] === '1' || $route.query['upgradation'] === '1' ? 6500 : null"
      @close="hideOverlay">
      <!-- Content of the overlay -->
      <div class="overlay-content">
        <logo style="width: 183px;" color="black" class="overlay-logo" />
        <div v-if="$route.query['email_verified'] === '1'">
          <email-verified-svg></email-verified-svg>
          <h2>Email Verified.</h2>
          <!-- <span style="color: blue;cursor: pointer">Login</span> -->
        </div>
        <div v-else-if="$route.query['upgradation'] === '1'">
          <h2>Your account upgradation request has been processed successfully. Thanks!</h2>
        </div>
        <div v-else>
          <div class="overLayClose" @click="hideOverlay">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <email-not-verified-svg></email-not-verified-svg>
          <h2>Registration complete</h2>
          <span>Go to your email to verify</span>
        </div>
        <!-- <img slot="imageSrc" src="/img/Resized_mail.gif" alt="verified" srcset="" /> -->
        <!-- <div v-html="overlayContent" @click="handleOverlayClick"></div> -->
      </div>
    </overlay-notify>
    <div class="page-main" v-if="!app.user.loggedin">
      <div class="login_container">
        <!-- <p class="centered-text" style="margin: 1em 0">
        Don't have an account? <a href="#" @click="showRegister">Register</a>
      </p> -->
        <!-- <logo style="width: 250px;" color="black" /> -->

        <form class="form-login" @submit.prevent="formSubmit">
          <input type="email" name="email" placeholder="Email" v-model="email" v-validate="'required|email'"
            :class="{ error: errors.has('email') }" data-vv-validate-on="focusout" />
          <span class="error-msg" v-if="errors.has('email')">{{
            errors.first("email")
            }}</span>

          <input type="password" name="password" placeholder="Password" v-model="password" v-validate="'required'"
            :class="{ error: errors.has('password') }" data-vv-validate-on="focusout" />
          <span class="error-msg" v-if="errors.has('password')">{{
            errors.first("password")
            }}</span>
          <span v-if="showMessage" style="color: red;font-size: 12px;">{{ showMessage }}</span>
          <!-- <div style="display: flex; justify-content: space-between"> -->
          <div class="form-controls">
            <div class="forgot_password" @click="showReset" style="font-size: small;"><a href="#"
                style="color: blue; text-decoration: none;">Forgot password?</a></div>
            <div class="remember_me"><label class="switch"><input type="checkbox" name="remember" id="remember"
                  v-model="remember"><span class="slider round"></span></label> Remember me</div>
          </div>
          <!-- </div> -->
          <div class="wrap">
            <button class="button" type="submit" v-show="!loading"><i class="fa fa-lock"></i> Sign In</button>
            <button class="button" disabled v-show="loading"><i class="fa fa-spinner fa-spin"></i></button>
          </div>
          <div class="register">
            <p style="font-size: small;">Don't have an account?<a href="#" style="text-decoration: none; color: blue;">
                Register.</a></p>
          </div>
        </form>
      </div>
    </div>


    <div class="layout-wave-two">
      <img src="/img/layered-waves1.svg" alt="" srcset="">
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OverlayNotify from "./../layout/overlay-notify.vue";
import EmailVerifiedSvg from "./../icons/email-verified.vue";
import EmailNotVerifiedSvg from "./../icons/email-not-verified.vue";
import Logo from "global/logo";

export default {
  components: {
    OverlayNotify,
    EmailVerifiedSvg,
    EmailNotVerifiedSvg,
    Logo
  },
  data() {
    return {
      variables: window.variables,
      loading: false,
      email: "",
      password: "",
      remember: false,
      deActivatedUser: false,
      banned: false,
      showMessage: false,
      isOverlayVisible: false,
      overlayContent: "",
    };
  },
  created: async function () {

    if (this.$route.query["notify"]) {
      await axios.get(`/api/auth/send-notify/${this.$route.query["notify"]}`);
    }

    if (this.$route.query["email_verified"] || (this.$route.query["upgradation"] && !this.app.user.loggedin)) {
      this.isOverlayVisible = true;
      this.$store.commit("app/unsetUser");
      await axios.get("/api/auth/logout");
    } else if (this.$route.query["upgradation"] && this.app.user.loggedin) {
      this.$router.push({ path: "/account" });
    } else {
      if (this.app.user.loggedin) {
        this.$router.push({ path: "/account" });
      }
    }
  },
  async mounted() { },
  computed: {
    ...mapState(["app"]),
  },

  methods: {
    handleOverlayClick(event) {
      const target = event.target;
      if (target.tagName === "SPAN" && target.style.color === "blue") {
        this.hideOverlay();
      }
    },

    hideOverlay() {
      this.isOverlayVisible = false;
      this.$router.push({ path: "/login" });
    },

    formSubmit: function () {
      this.$validator.validateAll().then((passes) => {
        const { email, password, remember } = this;

        if (!passes) return;

        this.loading = true;

        axios
          .post("/api/auth/login", { email, password, remember })
          .then(
            function (response) {
              this.loading = false;

              if (response.data.success) {
                this.username = "";
                this.password = "";

                this.$store.commit("app/setUser", response.data.user);
                // window.location = "/admin";
                location.reload()

              } else {
                if (response.data.deactivated) {
                  this.deActivatedUser = true;
                  this.showMessage = response.data.message;
                } else if (response.data.banned) {
                  this.banned = true;
                } else {
                  this.password = "";
                }
              }
            }.bind(this)
          )
          .catch(
            function (err) {
              this.loading = false;
              this.password = "";
            }.bind(this)
          );
      });
    },

    showReset: function () {
      this.$modal.show("reset-password");
    },

    showRegister: function () {
      this.$modal.show("modal-auth-register");
    },
  },
};
</script>

<style lang="scss" scoped>
.overLayClose {
  position: absolute;
  top: 10px;
  font-size: 50px;
  right: 10px;
  cursor: pointer;
}

.overlay-logo {
  position: absolute;
  top: 10px;
  left: 44%;
}

.button {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  color: white;
  cursor: pointer;
  background-color: #3523FB;
  width: 100%;
  font-size: 16px;
  font-family: 'Comfortaa', sans-serif;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.layout-wave-one {
  width: 100%;
}

.layout-wave-one {
  img {
    position: absolute;
    top: 0;
  }
}

.layout-wave-two {
  width: 100%;
}

.layout-wave-two {
  img {
    position: absolute;
    bottom: 0;
  }
}

// .phase-logo-outline-bottom-right {
//   img {
//     position: absolute;
//     width: 40%;
//     bottom: 0px;
//     right: 0px;
//   }
// }

form.form-login {
  align-items: center;
  border-radius: 5px;
  padding: 10px 20px;
  transition: transform 0.2s;
  text-align: center;
  width: 400px;
  // height: 500px;
  margin: auto;

  input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 14px;
    box-sizing: border-box;
    border: 1px solid #3523FB;
    border-radius: 5px;

    &.error {
      border: 1px solid red;
      border-radius: 3px;
    }
  }

  .error-msg {
    color: red;
    font-size: 10px;
  }
}

.forgot_password {
  text-align: right;
  margin-bottom: 10px;
  width: 50%;
  float: right;
}

.remember_me {
  text-align: left;
  font-size: small;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 50%;
  float: left;
}

.switch {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #3523FB;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3523FB;
}

input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;

}

.form_controls {
  display: flex;
  align-items: center;
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100px;

}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.page-content-padded {
  margin: 0;
  font-family: 'Comfortaa', sans-serif;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.5;
  background: #fff;
  flex-direction: column;
}

.page-main {
  z-index: 9999;
}
</style>
