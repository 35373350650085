<template>
    <div class="page-content-padded" v-if="post">
        <div class="page-main page-post">
            <div class="main-info">
                <router-link :to="'/user/' + app.user.path" v-if="post && app?.user">
                    <div class="post-image">
                        <div class="post-label">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i> Go back to profile
                        </div>
                    </div>
                </router-link>
                <div class="item-info">
                    <div class="item-info-upper">
                        <div class="item-info-upper-left">

                            <div class="post-body">

                                {{ post.body }}
                            </div>
                            <div v-if="post.attachment" class="post-body">

                                <img v-if="post.attachment" :src="post.attachment.files.medium.url"
                                    :alt="post.attachment.alt" class="p-post-image" />
                            </div>
                            <div class="post-author">
                                <h2>By</h2>
                                <router-link :to="'/user/' + post.user.path" style="display: flex;gap: 5px">
                                    <avatar :src="post.user.avatar.files.medium.url" :size="50" :center="false" />
                                    <h3>
                                        {{ post.user.name }}
                                    </h3>
                                </router-link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="comments">
                <comments-container :commentable="post" />
            </div>
        </div>
    </div>
    <!-- <spinner style="margin: 3em auto;" v-else
             :animation-duration="1000"
             :size="80"
             color="black"
    /> -->
    <dotlottie-player v-else src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
        background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto;" loop
        autoplay></dotlottie-player>

</template>

<script>
import { mapState } from 'vuex';
import store from 'store';
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import CommentsContainer from "global/comments-container";
export default {
    data() {
        return {
            post: null,
        }
    },
    created: function () {
        this.fetchPost();
    },
    methods: {
        fetchPost() {
            axios.get('/api/post/' + this.$route.params.postid).then(response => {
                this.post = response.data;
                console.log(this.post);
            });
        }
    },
    components: {
        Spinner,
        CommentsContainer,
    },
    computed: mapState([
        'app',
    ]),
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h1 {
    font-size: 35px;
}

.page-post {
    padding: 3em;
}

.main-info {
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

.post-label {
    background-color: #e6e6e6;
    font-weight: bold;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 10px 12px;
    max-width: 168px;
}

.item-info {
    margin-left: 1em;
    flex: 1;
}

.item-info-upper {
    display: flex;
}

.item-info-upper-left {
    flex: 1;
}

h1 {
    margin: 0;
}

.post-body {
    font-size: 20px;
    margin: 1em 0;
    color: $color-grey2;
    line-height: 30px;
}

td {
    padding: 15px 0;
    text-align: center;
}

.comments {
    margin: 3em 0;
}

.post-author {
    display: inline-flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
}

.post-author h2 {
    color: $color-grey2;
}
</style>