<template>
  <div class="page-content-padded page-merch-view" v-if="merch">
    <div class="page-main page-merch">
      <div class="main-info">
        <div class="merch-breadcrumbs-block">
          <router-link :to="'/user/' + app.user.path" v-if="merch && app?.user">
            <avatar :src="merch.user.avatar.files.medium.url" :size="34" :center="false" />
          </router-link>
          <i class="fa fa-angle-right"></i>
          <router-link :to="'/user/' + app.user.path + '/merch'" v-if="merch && app?.user">
            <span>Merch</span>
          </router-link>
          <i class="fa fa-angle-right"></i>
          <span>{{ truncatedBody }}</span>
        </div>
        <!-- <div class="links-block">
          <router-link :to="'/user/' + app.user.path" v-if="merch && app?.user">
            <div class="merch-image">
              <div class="merch-label">
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Go back to profile
              </div>
            </div>
          </router-link>
          <a :href="merch?.links[0].link">
            <div class="merch-image">
              <div class="merch-label">
                Go to Merch <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            </div>
          </a>
        </div> -->

        <div class="item-info">
          <div v-if="merch.image">
            <img v-if="merch.image" :src="merch.image.files.medium.url" :alt="merch.image.alt" class="p-merch-image" />
          </div>
          <div class="item-info-upper">
            <div class="item-info-upper-left">
              <div class="title-header">
                <div class="title-header-row">
                  <h2 style="margin: 10px 0px;">{{ merch.title }}</h2>
                  <a :href="merch?.links[0].link" target="_blank" class="merch-buy-btn">Buy <i class="fa fa-external-link"></i></a>
                </div>
                <hr>
              </div>
              <div class="merch-body">
                <p>{{ merch.description }}</p>
              </div>

              <div class="merch-author">
                <hr>
                <div class="merch-author-action-row">
                  <router-link :to="'/user/' + merch.user.path" style="display: flex;gap: 10px;align-items: center;">
                    <avatar :src="merch.user.avatar.files.medium.url" :size="26" :center="false" />
                    <span>{{ merch.user.name }}</span>
                    <span>{{ formattedDate }}</span>
                  </router-link>
                  <div class="merch-author-action-row">
                    <social-sharing inline-template>
                      <span style="display: flex;font-size: 30px;gap: 12px;margin-right: 12px;">
                        <network network="twitter">
                          <i class="fab fa-twitter-square" style="cursor: pointer;"></i>
                        </network>
                        <network network="facebook">
                          <i class="fab fa-facebook" style="cursor: pointer;"></i>
                        </network>
                      </span>
                    </social-sharing>
                    <actions :actionable="merch" :id="merch.id" :actiontype='"merch"' :isSingle="true">
                    </actions>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <dotlottie-player v-else src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
    background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto;" loop
    autoplay></dotlottie-player>
</template>

<script>
import { mapState } from 'vuex';
import SidebarGroup from 'global/sidebar-group';
import store from 'store';
// import CommentsContainer from "global/comments-container";
import Actions from 'global/actions/actions';

export default {
  components: {
    SidebarGroup,
    Actions
    // CommentsContainer,
  },
  data() {
    return {
      merch: null,
    }
  },
  created: function () {
    this.fetchMerch();
  },
  methods: {
    fetchMerch() {
      axios.get('/api/merch/' + this.$route.params.merchid).then(response => {
        this.merch = response.data;
      });
    }
  },
  computed: {
    ...mapState(['app']),
    truncatedBody() {
      return this.merch.title.length > 20
        ? this.merch.title.substring(0, 20) + "..."
        : this.merch.title;
    },
    formattedDate() {
      return moment(this.merch.created_at).format('MMM Do, YYYY');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h1 {
  font-size: 35px;
}

.page-merch {
  padding: 3em;
}

.main-info {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

.merch-label {
  background-color: #000000;
  font-weight: bold;
  margin: 0 0 10px;
  border-radius: 8px;
  padding: 10px 12px;
  max-width: 168px;
}

.item-info {
  margin: 1.5rem 0;
  // flex: 1;
  display: flex;
  width: 100%;
  gap: 10px;
}

.item-info-upper {
  display: flex;
  background-color: #E6E6E6;
  padding: 10px;
  width: 100%;
}

.item-info-upper-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
}

h1 {
  margin: 0;
}

.title-header-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merch-body {
  font-size: 20px;
  margin: 1em 0;
  color: #000000;
  line-height: 30px;
}

td {
  padding: 15px 0;
  text-align: center;
}

.comments {
  margin: 3em 0;
}

.merch-author {
  // display: inline-flex;
  // justify-content: flex-start;
  // gap: 5px;
  align-items: center;
  margin-top: auto;
}

.merch-author h2 {
  color: $color-grey2;
}

.links-block {
  display: flex;
  justify-content: space-between;
}

.merch-breadcrumbs-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.merch-author-action-row {
  display: flex;
  justify-content: space-between;
  //   align-items: center;
}

.actions {
  font-size: 20px;
  gap: 4px;
}
.merch-buy-btn{
  color: #ffff;
  background-color: #3523FB;
  border: 1px solid #3523FB;
  border-radius: 20px;
  padding: 10px 20px;
}
</style>
