<template>
  <div class="page-content-padded page-event-view" v-if="event">
    <div class="page-main page-event">
      <div class="main-info">
        <div class="links-block">
          <router-link :to="'/user/' + app.user.path" v-if="event && app?.user">
            <div class="event-image">
              <div class="event-label">
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Go back to profile
              </div>
            </div>
          </router-link>
          <a :href="event?.url">
            <div class="event-image">
              <div class="event-label">
                Go to event <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            </div>
          </a>
        </div>

        <div class="item-info">
          <div class="item-info-upper">
            <div class="item-info-upper-left">

              <div class="event-body">
                <div v-if="event.image" class="event-image">
                  <img v-if="event.image" :src="event.image.files.medium.url" :alt="event.image.alt"
                    class="p-event-image" />
                </div>
                <div class="event-details">
                  <h2>{{ event.name }}</h2>
                  <span> <i class="fa fa-map-pin" aria-hidden="true"></i> {{ event.location }}</span>
                  <span> <i class="fa fa-calendar" aria-hidden="true"></i> {{ formatDate(event.date) }}</span>
                </div>
              </div>

              <div class="event-author">
                <h2>By</h2>
                <router-link :to="'/user/' + event.user.path" style="display: flex;gap: 5px">
                  <avatar :src="event.user.avatar.files.medium.url" :size="50" :center="false" />
                  <h3>
                    {{ event.user.name }}
                  </h3>
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <dotlottie-player v-else src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
    background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto;" loop
    autoplay></dotlottie-player>
</template>

<script>
import { mapState } from 'vuex';
import SidebarGroup from 'global/sidebar-group';
import store from 'store';
import moment from 'moment';
// import CommentsContainer from "global/comments-container";

export default {
  components: {
    SidebarGroup,
    // CommentsContainer,
  },
  data() {
    return {
      event: null,
    }
  },
  created: function () {
    this.fetchevent();
  },
  methods: {
    fetchevent() {
      axios.get('/api/event/' + this.$route.params.eventid + '/get').then(response => {
        this.event = response.data;
      });
    },
    formatDate(date) {
      return moment(date).format("MMMM D, YYYY");
    }
  },
  computed: mapState([
    'app',
  ])
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h1 {
  font-size: 35px;
}

.page-event {
  padding: 3em;
}

.main-info {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

.event-label {
  background-color: #e6e6e6;
  font-weight: bold;
  margin: 0 0 10px;
  border-radius: 8px;
  padding: 10px 12px;
  max-width: 168px;
}

.item-info {
  margin-left: 1em;
  flex: 1;
}

.item-info-upper {
  display: flex;
}

.item-info-upper-left {
  flex: 1;
}

h1 {
  margin: 0;
}

.event-body img {
  max-width: 500px;
  border-radius: 8px;
}

.event-body .event-details {
  display: flex;
  flex-direction: column;
}

.event-body {
  font-size: 20px;
  margin: 1em 0;
  color: $color-grey2;
  line-height: 30px;
  display: flex;
  gap: 10px;
}

td {
  padding: 15px 0;
  text-align: center;
}

.comments {
  margin: 3em 0;
}

.event-author {
  display: inline-flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.event-author h2 {
  color: $color-grey2;
}

.links-block {
  display: flex;
  justify-content: space-between;
}
</style>
