import { render, staticRenderFns } from "./update-post.vue?vue&type=template&id=5df26989&scoped=true&"
import script from "./update-post.vue?vue&type=script&lang=js&"
export * from "./update-post.vue?vue&type=script&lang=js&"
import style0 from "./update-post.vue?vue&type=style&index=0&id=5df26989&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df26989",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5df26989')) {
      api.createRecord('5df26989', component.options)
    } else {
      api.reload('5df26989', component.options)
    }
    module.hot.accept("./update-post.vue?vue&type=template&id=5df26989&scoped=true&", function () {
      api.rerender('5df26989', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/modals/update-post.vue"
export default component.exports