var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.video.title &&
    _vm.video.asset &&
    _vm.video.asset.files.video_thumbnail
    ? _c(
        "div",
        { staticClass: "video video-clear" },
        [
          !_vm.isEditing
            ? _c("actions", {
                staticStyle: { "justify-content": "flex-end !important" },
                attrs: {
                  actionable: _vm.video,
                  id: _vm.id,
                  actiontype: "video",
                },
                on: { "edit-video": _vm.enableEditMode },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "p-video-main" }, [
            _c("div", { staticClass: "p-video-text" }, [
              _vm.isEditing
                ? _c("div", { staticClass: "p-video-detail" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedVideoTitle,
                          expression: "editedVideoTitle",
                        },
                      ],
                      staticClass: "form-control video-editable-input",
                      attrs: { type: "text", name: "", id: "" },
                      domProps: { value: _vm.editedVideoTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.editedVideoTitle = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedVideoBody,
                          expression: "editedVideoBody",
                        },
                      ],
                      staticClass: "form-control video-editable-input",
                      attrs: { rows: "5", cols: "60" },
                      domProps: { value: _vm.editedVideoBody },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.editedVideoBody = $event.target.value
                        },
                      },
                    }),
                  ])
                : _c("div", { staticClass: "p-video-detail" }, [
                    _c("h5", [_vm._v(_vm._s(_vm.video.title))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.video.description))]),
                  ]),
              _vm._v(" "),
              _vm.isEditing
                ? _c(
                    "div",
                    { staticClass: "p-video-detail video-editable-actions" },
                    [
                      _c(
                        "ph-button",
                        {
                          attrs: {
                            size: "small",
                            loading: _vm.submitting,
                            color: "primary-outline",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.savePost.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ph-button",
                        {
                          attrs: { size: "small", color: "danger-outline" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.cancelEdit.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "video",
              {
                attrs: {
                  controls: "",
                  width: "100%",
                  height: "300",
                  id: "video" + _vm.video.id,
                  poster: _vm.video.asset.files.video_thumbnail.url,
                },
              },
              [
                _c("source", {
                  attrs: {
                    src: _vm.video.asset.files.original.url,
                    type: _vm.video.asset.files.original.mime,
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }