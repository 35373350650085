var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [
            _vm.releases.data.length
              ? _c(
                  "div",
                  { staticClass: "release-wrapper" },
                  [
                    _vm._l(_vm.releases.data, function (release) {
                      return _c("my-music-release", {
                        key: release.id,
                        attrs: { release: release },
                      })
                    }),
                    _vm._v(" "),
                    _vm.hasAnotherPage && _vm.releases.data.length
                      ? _c(
                          "div",
                          {
                            staticClass: "centered-text",
                            staticStyle: { margin: "4em 0" },
                          },
                          [
                            _c(
                              "ph-button",
                              {
                                attrs: {
                                  size: "large",
                                  loading: _vm.loadingNextPage,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.loadNextPage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("\n          Show Me More\n        ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _c("div", [
                  _c("p", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(
                      "\n        This user has not released any music yet.\n      "
                    ),
                  ]),
                ]),
          ])
        : _c("dotlottie-player", {
            staticStyle: { width: "200px", height: "200px", margin: "auto" },
            attrs: {
              src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
              background: "transparent",
              speed: "1",
              loop: "",
              autoplay: "",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }