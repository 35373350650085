<template>
  <div>
    <!-- <spinner style="margin: 3em auto;" :animation-duration="1000" :size="60" color="black" v-show="loadingMerch" /> -->
    <dotlottie-player src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json" background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto" loop autoplay v-show="loadingMerch"></dotlottie-player>
    
    <div v-if="merches.length">
      <item v-for="merch in merches" :item="merch" :key="merch.id" />
    </div>
    <div v-if="!merches.length && !loadingMerch">
      <span class="not-found" v-if="!isShow">
        Sorry, this user hasn’t uploaded any merchandise!
      </span>
      <ph-button size="medium" style="display: block; text-align: center;" @click.native="showMerchCreateModal()"
      v-if="isShow && isPro">>Add Merch</ph-button>
    </div>
  </div>
</template>

<script>
import ProfileMixin from '../profile-mixin';
import { HalfCircleSpinner as Spinner } from 'epic-spinners';
import Item from 'global/items/item';
import { UserEvents, SocialEvents } from "events";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loadingMerch: false,
      merches: [],
    }
  },
  computed: {
    isPro: function () {
      return (this.app.user.account_type === 'pro' || this.app.user.account_type === 'admin')
    },
    isShow: function () {
      return (this.app.user.id === this.user.id)
    },
    ...mapState([
      'app'
    ])
  },
  created: function () {
    this.fetchMerch();
    UserEvents.$on('merch-added', () => {
      this.fetchMerch();
    });
    SocialEvents.$on('merch-deleted', () => {
      this.fetchMerch();
    });
  },
  methods: {
    showMerchCreateModal() {
      this.$modal.show('modal-create-merch', { user: this.user });
    },
    fetchMerch() {
      this.loadingMerch = true;
      axios.get('/api/user/' + this.user.id + '/merch').then(response => {
        this.merches = response.data;
      }).finally(() => {
        this.loadingMerch = false;
      });
    }
  },
  mixins: [
    ProfileMixin
  ],
  components: {
    Item,
    Spinner
  }
}
</script>
<style lang="scss" scoped>
.not-found {
  text-align: center;
  margin: 18px 0px;
  font-size: 20px;
  display: block;
  font-weight: 700;
}
</style>