var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slideout-wrap" }, [
    _c(
      "div",
      { staticClass: "slideout-menu", attrs: { id: "slideout-menu" } },
      [
        _c(
          "div",
          {
            staticClass: "slideout-top",
            attrs: { id: "slideout-close" },
            on: { click: _vm.toggle },
          },
          [_c("close-icon", { staticClass: "float-right" })],
          1
        ),
        _vm._v(" "),
        _c("navigation-list", {
          attrs: { items: _vm.navigation.slideout_menu, menu: "slideout_menu" },
          on: { menuClicked: _vm.toggle },
        }),
        _vm._v(" "),
        _vm.$store.state.app.user.loggedin
          ? _c("div", [
              _c("hr", { staticStyle: { margin: "0px 22px" } }),
              _vm._v(" "),
              _c("ul", [
                _vm.$store.state.app.user.roles[0].name == "admin"
                  ? _c("li", [
                      _c("a", { attrs: { href: "/admin" } }, [_vm._v("Admin")]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", [
                  _c("i", { staticClass: "fa fa-sign-out-alt" }),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { href: "/#" }, on: { click: _vm.logout } },
                    [
                      _vm.loggingOut
                        ? _c("span", { staticClass: "spinner" })
                        : _c("span", [_vm._v("Logout")]),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(2),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/news" } }, [
        _c("i", { staticClass: "fa fa-newspaper" }),
        _vm._v("\n                        News\n                    "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("i", { staticClass: "fa fa-question-circle" }),
      _vm._v(" "),
      _c("a", { attrs: { href: "/help" } }, [
        _vm._v(
          "\n                        Help & Support\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slideout-social" }, [
      _c("div", { staticClass: "social-icon" }, [
        _c(
          "a",
          { attrs: { href: "https://instagram.com", target: "_blank" } },
          [_c("i", { staticClass: "fab fa-instagram" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "social-icon" }, [
        _c("a", { attrs: { href: "https://facebook.com", target: "_blank" } }, [
          _c("i", { staticClass: "fab fa-facebook-f" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "social-icon" }, [
        _c("a", { attrs: { href: "https://twitter.com", target: "_blank" } }, [
          _c("i", { staticClass: "fab fa-twitter" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }