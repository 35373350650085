<template>
    <div v-if="postBody != ''" :class="'p-item masonry-item ' + item.component +'-' + item.id">

        <!-- <div class="p-item-image"> -->
        <div class="p-item-head">
            <router-link :to="getRouterObject(item)">
                <avatar :size="60" :src="item.user.avatar.files.thumb.url" />
            </router-link>
            <div class="p-item-detail">
                <div class="p-item-title">
                    <span>{{ item.user.name }}</span>
                    <span class="p-item-date-time">Posted {{ formatDate(item.created_at) }}</span>
                </div>
            </div>
        </div>

        <!-- </div> -->

        <div class="p-item-main">
            <div class="p-post-text">
                <img v-if="item.attachment" :src="item.attachment.files.medium.url" :alt="item.attachment.alt"
                    class="p-post-image" />
                <!-- <router-link
                :to="getRouterObject(item)"> -->
                <p class="p-item-body">{{ postBody }}</p>
                <!-- </router-link> -->
            </div>
            <div class="p-item-meta">
                <div class="p-item-counts">
                    <span>{{ item.comments_count }} comments</span>
                    <span>{{ item.likes_count }} Likes</span>
                    <span>{{ item.shares_count }} shares</span>
                </div>
                <actions v-on:delete-action="deletedItem" :actionable="item" :id="item.action_id" :isFeed="true"></actions>
            </div>
        </div>
    </div>
</template>

<script>
import Actions from 'global/actions/actions';
import ActionMenu from 'global/actions/action-menu';
import Avatar from 'global/avatar';
import { SocialEvents } from "../../../event-bus";
import moment from 'moment';

export default {
    props: { item: Object },
    created: function () {
        SocialEvents.$on('delete-action', this.deletedItem)
    },
    data() {
        return {
            isDeleted: (this.item.deleted == true) ? true : false,
        }
    },
    computed: {
        postBody() {
            if (this.item.body) {
                return this.item.body;
            } else {
                return '';
            }
        }
    },
    methods: {
        deletedItem() {
            var action_id = localStorage.getItem('last_deleted_action_id');
            if (this.item.action_id == action_id) {
                this.item.deleted = true;
                this.isDeleted = true;
                this.item.body = '';
            }

        },
        formatDate(date) {
            return moment(date).format('MMMM Do [at] h:mm A'); // January 12th at 11:02 AM
        }
    },
    components: {
        Actions,
        ActionMenu,
        Avatar,
    }
};
</script>

<style lang="scss" scoped>
.p-item {
    align-items: flex-start;
    flex-direction: column;
    padding: 8px 14px;
    background-color: #e6e6e6
}

.p-item-head {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}

.p-post-text {
    flex: 1;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2em;
}

.p-item-body {
    font-size: 16px;
    margin: 6px;
    color: black;
}

.p-item-title {
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-size: 19px;
    color: #366efc;
}

.p-item-date-time {
    color: #aea7a7;
    font-size: 10px;
}

.p-item-main {
    width: 100%;
    justify-content: flex-start;
}

.p-item-counts {
    display: flex;
    gap: 8px; 
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.p-item-counts span:not(:last-child)::after {
    content: " | ";
    margin-left: 5px; 
}

.p-post-image {
    height: 120px;
    width: auto;
    margin-bottom: 1em;
}
.p-item-meta{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
