var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.merch
    ? _c("div", { staticClass: "page-content-padded page-merch-view" }, [
        _c("div", { staticClass: "page-main page-merch" }, [
          _c("div", { staticClass: "main-info" }, [
            _c(
              "div",
              { staticClass: "links-block" },
              [
                _vm.merch && _vm.app?.user
                  ? _c(
                      "router-link",
                      { attrs: { to: "/user/" + _vm.app.user.path } },
                      [
                        _c("div", { staticClass: "merch-image" }, [
                          _c("div", { staticClass: "merch-label" }, [
                            _c("i", {
                              staticClass: "fa fa-arrow-left",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" Go back to profile\n          "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("a", { attrs: { href: _vm.merch?.links[0].link } }, [
                  _vm._m(0),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item-info" }, [
              _c("div", { staticClass: "item-info-upper" }, [
                _c("div", { staticClass: "item-info-upper-left" }, [
                  _c("div", { staticClass: "merch-body" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.merch.title))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.merch.description))]),
                  ]),
                  _vm._v(" "),
                  _vm.merch.image
                    ? _c("div", { staticClass: "merch-body" }, [
                        _vm.merch.image
                          ? _c("img", {
                              staticClass: "p-merch-image",
                              attrs: {
                                src: _vm.merch.image.files.medium.url,
                                alt: _vm.merch.image.alt,
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "merch-author" },
                    [
                      _c("h2", [_vm._v("By")]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticStyle: { display: "flex", gap: "5px" },
                          attrs: { to: "/user/" + _vm.merch.user.path },
                        },
                        [
                          _c("avatar", {
                            attrs: {
                              src: _vm.merch.user.avatar.files.medium.url,
                              size: 50,
                              center: false,
                            },
                          }),
                          _vm._v(" "),
                          _c("h3", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.merch.user.name) +
                                "\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _c("dotlottie-player", {
        staticStyle: { width: "200px", height: "200px", margin: "auto" },
        attrs: {
          src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      })
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "merch-image" }, [
      _c("div", { staticClass: "merch-label" }, [
        _vm._v("\n            Go to Merch "),
        _c("i", {
          staticClass: "fa fa-arrow-right",
          attrs: { "aria-hidden": "true" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }