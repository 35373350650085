<template>
    <div class="player" ref="player" v-show="$store.state.player.status.set">
        <audio ref="audio" @loadedmetadata="loaded" @timeupdate="timeUpdate" @ended="ended" crossorigin="anonymous">
            <source :src="trackUrl" type="audio/mp3" v-if="$store.state.player.status.set">
        </audio>
        <div class="player">
            <div class="details" v-if="track.release && track.release.uploader">
                <img :src="track.release.image.files.thumb.url" alt="">
                <router-link :to="`/track/${track.slug}`">
                    {{ truncatedTrackInfo }}
                </router-link>
            </div>
            <div class="details" v-else>
                {{ track.name }}
            </div>
            <!-- <div class="controls">  
                <div class="left">
                    <span class="control volume-wrap">
                        <span class="control" v-show="!$store.state.player.status.muted" @click="toggleMute">
                            <i class="fa fa-volume-up fa-fw" data-fa-transform="shrink-3"></i>
                        </span>
                        <span class="control" v-show="$store.state.player.status.muted" @click="toggleMute">
                            <i class="fa fa-volume-off fa-fw" data-fa-transform="shrink-3"></i>
                        </span>
                        <div class="volume-bar-wrap" @mousedown="onBarMouseDown">
                            <div class="volume-bar" ref="bar">
                                <div class="volume"
                                    :style="{ width: $store.state.player.status.muted ? 0 : `${Math.trunc(volume * 100)}%`, }">
                                </div>
                            </div>
                        </div>
                    </span>
                    <span class="control" @click="toggleCart" :class="{ active: isInCart($store.state.player.track) }">
                        <i class="fa fa-shopping-cart" data-fa-transform="shrink-3"></i>
                    </span>

                </div>
                <div class="center">
                    <span class="control" @click="skipBack">
                        <i class="fa fa-step-backward"></i>
                    </span>
                    &nbsp;
                    <span class="fa-layers fa-fw" @click="togglePlayback">
                        <span class="control">
                            <i class="fa fa-fw fa-circle" data-fa-transform="grow-13" style="color:blue"></i>
                        </span>
                        <span v-show="!$store.state.player.status.playing">
                            <i class="fa fw-fw fa-play" data-fa-transform="shrink-3" style="color:white"></i>
                        </span>
                        <span v-show="$store.state.player.status.playing">
                            <i class="fa fw-fw fa-pause" data-fa-transform="shrink-3" style="color:white"></i>
                        </span>
                    </span>
                    &nbsp;
                                       <span class="control" @click="skipForward">
                                           <i class="fa fa-step-forward"></i>
                                       </span>
                </div>
                <div class="right">
                    <span @click="toggleRepeat" class="controls-repeat" :class="{ active: $store.state.player.repeat }">
                        <i class="fa fa-redo" data-fa-transform="shrink-3"></i>
                    </span>
                    <like-button :likeable="track" @like="liked" @unlike="unliked" />
                    <share-button :shareable="track" />
                    <report-button :reportable="track" />
                    <span @click="shuffle = !shuffle" class="controls-shuffle"
                        :class="{ active: $store.state.player.shuffle }">
                        <i class="fa fa-random" data-fa-transform="shrink-3"></i>
                    </span>
                </div>
            </div> -->


            <div class="progress" ref="progress" @click="progressClick" @mousedown="startProgressDrag"
                @mousemove="doProgressDrag" @mouseup="endProgressDrag">
                <div class="bar" :style="'width:' + ($store.state.player.status.position * 100) + '%;'">
                    <span class="playhead"></span>
                </div>
            </div>

            <div class="progress-time">{{ remainingTimeFormatted }}</div>

            <div class="center controls">
                <span class="control" @click="skipBack">
                    <i class="fa fa-backward"></i>
                </span>
                &nbsp;
                <span class="fa-layers fa-fw" @click="togglePlayback">
                    <span class="control">
                        <i class="fa fa-fw fa-circle" data-fa-transform="grow-20"></i>
                    </span>
                    <span v-show="!$store.state.player.status.playing">
                        <i class="fa fw-fw fa-play" data-fa-transform="shrink-1" style="color:white"></i>
                    </span>
                    <span v-show="$store.state.player.status.playing">
                        <i class="fa fw-fw fa-pause" data-fa-transform="shrink-1" style="color:white"></i>
                    </span>
                </span>
                &nbsp;
                <span class="control" @click="skipForward">
                    <i class="fa fa-forward"></i>
                </span>
            </div>

            <div class="volume-control">
                <span class="control volume-wrap">
                    <span class="control" v-show="!$store.state.player.status.muted" @click="toggleMute">
                        <i class="fa fa-volume-up fa-fw" data-fa-transform="shrink-3"></i>
                    </span>
                    <span class="control" v-show="$store.state.player.status.muted" @click="toggleMute">
                        <i class="fa fa-volume-off fa-fw" data-fa-transform="shrink-3"></i>
                    </span>
                    <div class="volume-bar-wrap" @mousedown="onBarMouseDown">
                        <div class="volume-bar" ref="bar">
                            <div class="volume"
                                :style="{ width: $store.state.player.status.muted ? 0 : `${Math.trunc(volume * 100)}%`, }">
                                <span class="volume-playhead"></span>
                            </div>
                        </div>
                    </div>
                </span>
            </div>

            <canvas ref="canvas" width="255" height="40" style="position: relative;top: 5px;"></canvas>

            <div class="player-actions">
                <span v-show="isTrackFollowed" @click="unfollowArtist">
                    <i class="fa-solid fa-plus-circle icon-primary"></i>
                </span>
                <span v-show="!isTrackFollowed" @click="followArtist">
                    <i class="fa-solid fa-check-circle icon-success"></i>
                </span>
                <span>
                    <router-link :to="`/user/${track?.release?.uploader?.path}/`">
                        <i class="fa-solid fa-users"></i>
                    </router-link>
                </span>
                <like-button :likeable="track" @like="liked" @unlike="unliked" />

                <!-- <span v-show="!isTrackFav">
                    <i class="fa-solid fa-heart"></i>
                </span>
                <span v-show="isTrackFav">
                    <i class="fa-solid fa-heart" style="color: red;"></i>
                </span> -->

                <share-button :shareable="track" />

                <span class="control" @click="toggleCart" :class="{ active: isInCart($store.state.player.track) }">
                    <i class="fa fa-cart-plus" data-fa-transform="shrink-3"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PlayerEvents } from "events";
import ShareButton from "global/actions/share-button";
import ReportButton from "global/actions/report-button";
import LikeButton from "global/actions/like-button";


const barHeight = 40

export default {
    components: {
        ShareButton,
        ReportButton,
        LikeButton,
    },
    data() {
        return {
            // DOM Elements
            audio: null,
            player: null,
            rect: null,
            expanded: false,
            type: '',
            volume: 0.5,
            ctx: null,
            analyser: null,
            dataArray: null,
            bufferLength: 0,
            audioContext: null,
            animationId: null,
            currentTime: 0,
            duration: 0,
            isTrackFollowed: false,
            isTrackFav: false,
        }
    },
    computed: {
        ...mapGetters('cart', [
            'isInCart',
        ], 'app'),
        ...mapGetters({
            track: 'player/getTrack',
            trackType: 'player/getTrackByType'
        }),
        trackUrl() {
            console.log("tracksskk", this.track);
            const track = this.trackType(this.type)
            return track.files[this.type].url
        },
        remainingTimeFormatted() {
            return this.formatTime(this.duration - this.currentTime);
        },
        truncatedTrackInfo() {
            const info = `${this.track.release.uploader.name} - ${this.track.name}`;
            return info.length > 16 ? info.slice(0, 16) + '...' : info;
        },
    },
    mounted() {
        this.audio = this.$refs.audio;
        this.player = this.$refs.player;
        this.audio.volume = this.volume;
        /*
         * Event Listeners
         */
        // progress should be a number between 0 and 1 representing a percentage
        PlayerEvents.$on('setProgress', progress => {
            this.setProgress(progress);
        });
        PlayerEvents.$on('setTrack', data => {
            this.setTrack(data.track);
            this.type = data.type
        });
        PlayerEvents.$on('setTogglePlayback', () => {
            this.togglePlayback();
        });
        PlayerEvents.$on('pause', () => {
            this.pause();
        });

        // Web Audio API setup
        //this.setupAudioContext();

        // this.$watch(
        //     () => this.$store.state.player.status.set,
        //     (newValue, oldValue) => {
        //         if (newValue) {
        //             this.audioContext = null;
        //             this.setupAudioContext();
        //         }
        //     },
        //     { immediate: true }
        // );
    },
    watch: {
        '$store.state.player.status.set': {
            handler(newValue) {
                if (newValue) {
                    this.audioContext = null;
                    this.setupAudioContext();
                }
            },
            immediate: true
        },
        'track': {
            handler(newValue) {
                if (newValue) {
                    this.isFollowing();
                    this.isFav();
                }
            },
            immediate: true
        },
    },
    methods: {
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        updateTime() {
            this.currentTime = this.$refs.audio.currentTime;
        },
        updateDuration() {
            this.duration = this.$refs.audio.duration;
        },
        loaded() {
            this.$store.commit('player/setMaxTime', this.audio.duration);
        },
        expandPlayer() {
            this.expanded = !this.expanded
        },
        timeUpdate() {
            this.$store.commit('player/setCurrentTime', this.audio.currentTime);
            this.$store.commit('player/setPosition', this.$store.state.player.status.time.current / this.$store.state.player.status.time.max);
            PlayerEvents.$emit('broadcastTimeUpdated', this.$store.state.player.status.position);
            if (this.audio.paused)
                PlayerEvents.$emit('broadcastPaused');
            else
                PlayerEvents.$emit('broadcastPlayed');
        },
        ended() {
            this.audio.currentTime = 0;
            this.$store.commit('player/setPlaying', false);
            PlayerEvents.$emit('broadcastPaused');
        },
        togglePlayback() {
            if (this.audio.paused) {
                this.play();
            } else {
                this.pause();
            }
        },
        play() {
            this.$store.commit('player/setPlaying', true);
            this.audio.play();
            PlayerEvents.$emit('broadcastPlayed');
        },
        pause() {
            this.$store.commit('player/setPlaying', false);
            this.audio.pause();
            PlayerEvents.$emit('broadcastPaused');
        },
        toggleMute() {
            if (this.audio.muted) {
                this.$store.commit('player/setMuted', false);
                this.audio.muted = false;
                PlayerEvents.$emit('broadcastUnmuted');
            } else {
                this.$store.commit('player/setMuted', true);
                this.audio.muted = true;
                PlayerEvents.$emit('broadcastMuted');
            }
        },
        toggleRepeat() {
            //if(this.repeat) {
            if (this.$store.state.player.repeat) {
                this.audio.loop = false;
                this.$store.commit('player/setRepeat', false);
            } else {
                this.audio.loop = true;
                this.$store.commit('player/setRepeat', true);
            }
        },
        toggleCart() {
            console.log("toggleCart");
            if (this.isInCart(this.$store.state.player.track)) {
                this.removeFromCart()
            } else {
                this.addToCart()
            }
        },
        addToCart() {
            console.log(this.$store.state.player.track);
            this.$store.dispatch('cart/addItem', this.$store.state.player.track);
        },
        removeFromCart() {
            this.$store.dispatch('cart/removeItem', this.$store.state.player.track);
        },
        progressClick(event) {
            const playerwidth = this.$refs.progress.clientWidth;
            const clickLoc = event.clientX - this.$refs.progress.getBoundingClientRect().left;
            const newPosition = clickLoc / playerwidth;
            this.$store.commit('player/setPosition', newPosition);
            this.setProgress(newPosition);
        },
        startProgressDrag() {
            this.$store.commit('player/setDragging', true);
        },
        doProgressDrag(event) {
            if (this.$store.state.player.status.dragging) {
                this.progressClick(event);
            }
        },
        endProgressDrag() {
            this.$store.commit('player/setDragging', false);
        },
        // Requires artist, name. We will know more about the track object when the track API is done
        setTrack(track) {
            console.log("set track", track);
            console.log("user", this.$store.state.app.user);
            this.$store.commit('player/setTrack', track);
            this.audio.load();
            setTimeout(() => {
                this.rect = this.player.getBoundingClientRect();
            }, 0);
        },
        setProgress(percent) {
            this.audio.currentTime = this.audio.duration * percent;
        },
        skipBack() {
            this.audio.currentTime -= 5;
        },
        skipForward() {
            this.audio.currentTime += 5;
        },
        setVolume(volume) {
            this.volume = volume
            this.audio.volume = volume
            if (volume > 0) {
                this.audio.muted = false
            }
        },
        adjustVolume(e) {
            let percentage = ((barHeight + e.clientX - this.getElementViewLeft(this.$refs.bar)) / barHeight) - 1
            percentage = percentage > 0 ? percentage : 0
            percentage = percentage < 1 ? percentage : 1
            this.setVolume(percentage)
        },
        onBarMouseDown() {
            document.addEventListener('mousemove', this.onDocumentMouseMove)
            document.addEventListener('mouseup', this.onDocumentMouseUp)
        },
        onDocumentMouseMove(e) {
            let barWidth = this.$refs.bar.offsetWidth; // Dynamically get the bar width
            let percentage = (e.clientX - this.getElementViewLeft(this.$refs.bar)) / barWidth;
            percentage = Math.min(Math.max(percentage, 0), 1); // Ensure percentage is between 0 and 1
            this.setVolume(percentage);
        },
        onDocumentMouseUp(e) {
            document.removeEventListener('mouseup', this.onDocumentMouseUp);
            document.removeEventListener('mousemove', this.onDocumentMouseMove);
            let barWidth = this.$refs.bar.offsetWidth; // Dynamically get the bar width
            let percentage = (e.clientX - this.getElementViewLeft(this.$refs.bar)) / barWidth;
            percentage = Math.min(Math.max(percentage, 0), 1); // Ensure percentage is between 0 and 1
            this.setVolume(percentage);
        },
        getElementViewLeft(element) {
            let actualLeft = element.offsetLeft
            let current = element.offsetParent
            let elementScrollLeft
            while (current !== null) {
                actualLeft += current.offsetLeft
                current = current.offsetParent
            }
            elementScrollLeft = document.body.scrollLeft + document.documentElement.scrollLeft
            return actualLeft - elementScrollLeft
        },
        liked() {
            if (this.track.release) {
                this.track.release.is_liked = true;
            } else {
                this.track.is_liked = true;
            }
        },
        unliked() {
            if (this.track.release) {
                this.track.release.is_liked = false;
            } else {
                this.track.is_liked = false;
            }
        },
        // getRandomHeight() {
        //     return Math.floor(Math.random() * this.canvas.height);
        // },
        // drawBars() {
        //     this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        //     this.bars.forEach((barHeight, i) => {
        //         const x = i * (this.barWidth + this.barGap);
        //         this.ctx.fillStyle = 'blue';
        //         this.ctx.fillRect(x, this.canvas.height - barHeight, this.barWidth, barHeight);
        //         this.ctx.strokeStyle = 'blue';
        //         this.ctx.strokeRect(x, this.canvas.height - barHeight, this.barWidth, barHeight);
        //     });
        // },
        // updateBars() {
        //     for (let i = 0; i < this.bars.length; i++) {
        //         this.bars[i] = this.getRandomHeight();
        //     }
        //     this.drawBars();
        // },
        // animate() {
        //     this.updateBars();
        //     requestAnimationFrame(this.animate);
        // },
        // setupAudioContext() {
        //     const audio = this.$refs.audio;
        //     audio.pause();
        //     audio.load();

        //     if (!this.audioContext) {
        //         this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        //     }

        //     if (this.source) {
        //         // Disconnect the existing source to prevent multiple connections
        //         this.source.disconnect();
        //         this.source = null;
        //     }

        //     audio.addEventListener('canplay', () => {
        //         try {
        //             // Create a new MediaElementSourceNode
        //             this.source = this.audioContext.createMediaElementSource(audio);

        //             // Connect the source to the analyser
        //             this.analyser = this.audioContext.createAnalyser();
        //             this.analyser.fftSize = 256;
        //             this.source.connect(this.analyser);
        //             this.analyser.connect(this.audioContext.destination);

        //             // Set up the data array for the analyser
        //             this.bufferLength = this.analyser.frequencyBinCount;
        //             this.dataArray = new Uint8Array(this.bufferLength);

        //             // Start visualizing
        //             this.visualize();
        //         } catch (error) {
        //             console.error('Error creating MediaElementSource:', error);
        //         }
        //     }, { once: true }); // Ensure 'canplay' event listener is called only once

        //     audio.load(); // Start loading the audio element
        // },
        setupAudioContext() {
            const audio = this.$refs.audio;

            // Check if the audio is ready to be played before calling play()
            const handlePlay = () => {
                audio.play().catch(error => console.error('Play failed:', error));
                // Remove the event listener after the first play attempt
                audio.removeEventListener('canplay', handlePlay);
            };

            // Set up AudioContext only if it does not exist
            if (!this.audioContext) {
                this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            }

            // Clean up existing source if any
            if (this.source) {
                this.source.disconnect();
                this.source = null;
            }

            // Ensure the analyser is connected properly
            this.analyser = this.audioContext.createAnalyser();
            this.analyser.fftSize = 256;

            // Create a new MediaElementSourceNode when the audio can play
            audio.addEventListener('canplay', () => {
                try {
                    this.source = this.audioContext.createMediaElementSource(audio);
                    this.source.connect(this.analyser);
                    this.analyser.connect(this.audioContext.destination);

                    // Set up the data array for the analyser
                    this.bufferLength = this.analyser.frequencyBinCount;
                    this.dataArray = new Uint8Array(this.bufferLength);

                    // Start visualizing
                    this.visualize();
                } catch (error) {
                    console.error('Error creating MediaElementSource:', error);
                }
            }, { once: true });
            audio.addEventListener('timeupdate', this.updateTime);
            audio.addEventListener('loadedmetadata', this.updateDuration);

            // Handle play when audio is ready
            audio.addEventListener('canplay', handlePlay, { once: true });

            // Load the audio element if it is not in a ready state
            if (audio.readyState < 2) { // Less than HAVE_CURRENT_DATA
                audio.load();
            } else {
                handlePlay();
            }
        },

        visualize() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const width = canvas.width;
            const height = canvas.height;

            const draw = () => {
                // Clear the canvas
                ctx.clearRect(0, 0, width, height);

                // Get the frequency data
                this.analyser.getByteFrequencyData(this.dataArray);

                // Draw the frequency data
                const barWidth = (width / this.bufferLength) * 2.5;
                let barHeight;
                let x = 0;

                for (let i = 10; i < this.bufferLength; i++) {
                    barHeight = this.dataArray[i];
                    const gradient = ctx.createLinearGradient(0, 0, 0, height);
                    gradient.addColorStop(0, 'rgba(0, 230, 255, 1)');
                    gradient.addColorStop(1, 'rgba(71, 171, 255, 1)');
                    ctx.fillStyle = gradient;
                    ctx.fillRect(x, height - barHeight / 2, barWidth, barHeight / 2);
                    ctx.roundRect(x, height - barHeight / 2, barWidth, barHeight / 2, 8);

                    x += barWidth + 5;
                }

                // Loop the drawing function
                this.animationId = requestAnimationFrame(draw);
            };

            draw(); // This call starts the visualization loop
        },
        followArtist() {
            var id = this.track ? this.track?.artist?.id ?? this.track?.uploaded_by : null;
            axios.get('/api/user/follow/' + id)
                .then(() => {
                    this.isTrackFollowed = true;
                })
                .catch((error) => {
                    console.error('Error following artist:', error);
                });
        },
        unfollowArtist() {
            var id = this.track ? this.track?.artist?.id ?? this.track?.uploaded_by : null;
            axios.get('/api/user/unfollow/' + id)
                .then(() => {
                    this.isTrackFollowed = false;
                })
                .catch((error) => {
                    console.error('Error unfollowing artist:', error);
                });
        },
        isFollowing() {
            var id = this.track ? this.track?.artist?.id ?? this.track?.uploaded_by : null;
            const followedArtists = this.$store.state.app.user.getFollowed();
            if (followedArtists?.includes(id)) {
                this.isTrackFollowed = true;
            };
        },
        isFav() {
            axios.get('/api/user/' + this.track.id + '/isfavourite')
                .then((res) => {
                    this.isTrackFav = res.data ? true : false;
                })
                .catch((error) => {
                    console.error('Error unfollowing artist:', error);
                });
        },
    },
    beforeDestroy() {
        cancelAnimationFrame(this.animationId);
    }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.player {
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 12px;
    gap: 25px;

    .controls {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        text-align: center;
        // background: #e6e6e6;
        background: #fff;
        border-left: 1px solid;
        border-right: 1px solid;
        padding: 0px 14px;
        align-items: center;

        .control {
            cursor: pointer;
        }

        .fa-forward,
        .fa-backward {
            font-size: 35px;
        }

        .active {
            color: $color-blue;
        }


    }

    .details {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        text-align: center;
        width: 256.69px;

        a {
            text-decoration: none;
        }

        img {
            width: 41px;
            height: 41px;
        }
    }

    .progress {
        width: 300px;
        background: #979797;
        cursor: pointer;

        .bar {
            height: 3px;
            background: #000000;
            position: relative;

            .playhead {
                width: 25px;
                height: 25px;
                background: #000000;
                border-radius: 50%;
                position: absolute;
                top: -10px;
                right: -6.25px;
            }
        }
    }

    .expanded {
        z-index: 30;

        .controls-bottom {
            height: 40px;
            background: #e6e6e6;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .details {
            height: 18px;
            padding-top: 7px;
        }
    }

    .avatar {
        width: 100% !important;
        background: #e6e6e6 !important;
    }
}

.progress-time {
    font-size: 20px;
}

.volume-control {
    display: flex;
}

.volume-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    z-index: 0;

    .volume-bar-wrap {
        display: flex;
        width: 150px;
        z-index: -1;
        height: 40px;
        transition: all .2s ease;

        .volume-bar {
            position: relative;
            bottom: -16px;
            left: 25px;
            width: 100px;
            height: 5px;
            background: #aaa;
            border-radius: 5px;
            z-index: 1;

            .volume {
                background: #000000;
                position: absolute;
                bottom: 0;
                top: 0;
                transition: width 0.1s ease, background-color .3s;
                will-change: width;
                border-radius: 5px;

                .volume-playhead {
                    width: 25px;
                    height: 25px;
                    background: #000000;
                    border-radius: 50%;
                    position: absolute;
                    top: -10px;
                    right: -6.25px;
                }
            }
        }
    }
}

.fa-volume-high,
.fa-volume-off {
    font-size: 30px;
}

.player-actions {
    display: flex;
    gap: 25px;
    font-size: 30px;
    align-content: space-between;
}

.icon-primary {
    color: #3300ff;
}

.icon-success {
    color: #4BB543
}
</style>
