// Search Store
export default {
    namespaced: true,
    state: {
        fromSearch: false,
        visible: false,
        searchTerm: '',
        filters: {
            classes: [],
            genres: [],
            keys: [],
            bpm: [0, 200],
        },
    },
    mutations: {
        toggleSearch (state, value = null) {
            if(value === null) {
                state.visible = !state.visible;
            } else {
                state.visible = value;
            }
        },
        setSearchTerm (state, newSearchTerm) {
            state.searchTerm = newSearchTerm;
        },
        setFromSearch(state, value) {
            state.fromSearch = value;
        },
        setFilters(state, value){
            state.filters = value;
        }
    },
    actions: {},
    getters: {
        getSearchTerm: state => {
            return state.searchTerm;
        }
    }
}