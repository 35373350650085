<template>
    <div id="nav_wrapper">
        <header class="top-bar" :class="{ searching: search.visible, 'top-bar-border-bottom': app.user.loggedin }">
            <div class="logo-bars-block">
                <div class="top mobile-toggle" @click="$emit('sidebarToggle')"
                    :class="{ 'bars-primary': app.user.loggedin || $route.path !== '/' }">
                    <i class="fa-solid fa-bars"></i>
                </div>
                <router-link class="logo" to="/" v-if="app.user.loggedin">
                    <logo-lines style="width: 100px;" />
                </router-link>
            </div>

            <router-link class="logo" to="/" v-if="!app.user.loggedin && !search.visible">
                <logo style="width: 250px;" :color="logoColor" />
                <p class="tagline">
                    {{ app.settings['logo_title'] }}
                </p>
            </router-link>

            <div class="main-search" v-if="app.user.loggedin || (!app.user.loggedin && search.visible)">
                <form @submit.prevent="toggleSearch">
                    <input type="text" placeholder="SEARCH" v-model="searchTerm" class="search-input" />
                </form>
                <div class="search-toggle" @click="toggleSearch" :class="searchClass">
                    <i class="fa fa-search"></i>
                </div>
            </div>

            <!-- <div @click="closeSearch" class="search-close" v-show="search.visible">
                <i class="fa fa-times"></i>
            </div> -->

            <!-- <div class="user-actions" :class="{ 'right-padded': !app.user.loggedin }" v-if="!search.visible"> -->
            <div class="user-actions" :class="{ 'right-padded': !app.user.loggedin }">
                <div class="user-loggedIn-actions" v-if="app.user.loggedin">
                    <a class="user-bar-item fa-layers fa-fw" href="javascript:void(0)" v-if="$can('create releases')"
                        @click="showUpload">
                        <template v-if="!app.user.stripe_account_id || !app.user.approved_at" slot="tooltip">
                            <p v-if="!app.user.stripe_account_id">Complete verification in your account section</p>
                            <p v-if="app.user.stripe_account_id && !app.user.approved_at">We're just verifying your
                                account
                                first.</p>
                        </template>

                        <template slot="tooltip">
                            <p>Upload restriction reached: upgrade to receive unlimited uploads</p>
                        </template>
                        <i class="fa fa-upload"></i>
                        <!-- <img src="/img/top_bar_icons/upload.png" alt=""> -->
                    </a>
                    <router-link class="user-bar-item" to="/account/releases">
                        <i class="fa fa-music"></i>
                        <!-- <img src="/img/top_bar_icons/my_releases.png" alt=""> -->
                    </router-link>
                    <a class="user-bar-item" href="#" @click.prevent="toggleNotification">
                        <span class="fa-layers fa-fw">
                            <i class="fa fa-bell"></i>
                            <span class="fa-layers-counter message-counter" v-if="notificationsCount > 0">
                                {{ notificationsCount }}
                            </span>
                        </span>
                    </a>
                    <notification-dropdown :show="showNotificationDropdown" :notifications="notifications"
                        @dismiss="handleDismissNotification($event)"
                        @clearall="handleClearAllNotification($event)"></notification-dropdown>
                    <a class="user-bar-item" href="#" @click.prevent="toggleMessages">
                        <span class="fa-layers fa-fw">
                            <i class="fa fa-message"></i>
                            <!-- <img src="/img/top_bar_icons/messages.png" alt=""> -->
                            <span class="fa-layers-counter message-counter" v-if="messenger.unreadThreads.length">
                                {{ messenger.unreadThreads.length }}</span>
                        </span>
                    </a>
                    <message-dropdown :show="messages.show" :threads="messenger?.unreadThreads" />
                    <router-link class="user-bar-item" to="/account/mymusic">
                        <i class="fa fa-download"></i>
                        <!-- <img src="/img/top_bar_icons/my_music.png" alt=""> -->
                    </router-link>
                    <a class="user-bar-item fa-layers fa-fw" @click="showCart" href="#">
                        <i class="fa fa-shopping-cart"></i>
                        <!-- <img src="/img/top_bar_icons/cart.png" alt=""> -->
                        <span class="fa-layers-counter message-counter" v-if="cart.items.length">{{ cart.items.length
                            }}</span>
                    </a>
                    <router-link class="user-bar-item" :to="'/user/' + app.user.path">
                        <avatar v-if="app.user.avatar" :src="app.user.avatar.files.medium.url"
                            :alt="app.user.avatar.alt" :size="35">
                        </avatar>
                    </router-link>
                </div>

                <ph-button :color="buttonClass" other="thick" @click.native="showAuthModal" v-if="!app.user.loggedin">
                    Create Account >
                </ph-button>

                <ph-button v-if="!app.user.loggedin" :color="buttonClass" other="thick"
                    @click.native="$modal.show('modal-auth-login')">Sign In ></ph-button>

                <ph-button other="thick" @click.native="logout" :loading="loggingOut" v-else>
                    Logout
                </ph-button>
            </div>
        </header>
        <header class="responsive">
            <div class="top" id="mobile-toggle" @click="$emit('slideoutToggle')">
                <div class="top mobile-toggle">
                    <i class="fa fa-bars"></i>
                </div>
            </div>

            <router-link class="logo" to="/" v-if="!search.visible">
                <logo style="width: 145px;" />
            </router-link>

            <form v-if="search.visible">
                <input type="text" placeholder="SEARCH" v-model="searchTerm" v-on:keydown.enter.prevent="" />
            </form>

            <div class="search-toggle" @click="toggleSearch" :class="searchClass">
                <i class="fa fa-search"></i>
            </div>
        </header>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "store";
import PhButton from "global/ph-button";
import Logo from "global/logo";
import LogoLines from "global/logo-lines";
import { PlayerEvents } from "../../event-bus";
import { MessageEvents } from "../../event-bus";
import MessageDropdown from "../pages/user/messages/message-dropdown";
import NotificationDropdown from "../pages/user/notification-dropdown.vue";

export default {
    data() {
        return {
            loggingOut: false,
            searchTerm: "",
            path: "",
            showNotificationDropdown: false,
            notificationsCount: 0,
            notifications: [],
            messages: {
                show: false,
            },
        };
    },
    computed: {
        ...mapState(["app", "search", "messenger", "cart"]),
        buttonClass() {
            return this.$route.path === "/" ? "white-blur" : ""
        },
        logoColor() {
            if (this.app.user.loggedin) {
                return 'black'
            } else if (this.$route.path === "/") {
                return 'white'
            } else {
                return 'black'
            }
        },
        searchClass() {
            let classes = [];
            if (this.search.visible) {
                classes.push("searching");
            }
            if (this.$route.path === "/" && !this.app.user.loggedin) {
                classes.push("white");
            }

            return classes;
        }
    },
    watch: {
        'search.searchTerm'(newSearchTerm) {
            this.searchTerm = newSearchTerm;
        }
    },
    created: function () {
        this.path = this.$route.path;
    },
    mounted() {
        // document.addEventListener('keyup', function (event) {
        //     if (event.key == "Enter" && event.code == "Enter") {
        //         console.log(searchTerm);
        //         store.commit("search/toggleSearch");
        //         store.commit("search/setSearchTerm", searchTerm);
        //     }
        // });s
        document.addEventListener("click", this.handleClickOutside);
        this.notificationHandler();
        this.fetchNotifications();
        if (this.app.user.loggedin) {
            store.dispatch("messenger/fetchThreads");
            store.dispatch("app/fetchStripeAccountReq");
        }
    },
    destroyed() {
        document.removeEventListener("click", this.handleClickOutside);
    },

    methods: {
        toggleSearch: function () {
            if (!this.search.visible) {
                this.$store.commit("search/toggleSearch");
            }
            this.$store.commit("search/setSearchTerm", this.searchTerm);
        },
        closeSearch: function () {
            this.$store.commit("search/toggleSearch");
        },
        showAuthModal: function () {
            this.$modal.show("modal-auth-register");
        },
        showUpload: function () {
            this.$modal.show("modal-upload");
        },
        showCart: function () {
            this.$modal.show("modal-cart");
        },
        toggleNotification: function () {
            this.showNotificationDropdown = !this.showNotificationDropdown;
        },
        toggleMessages: function () {
            this.messages.show = !this.messages.show;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.messages.show = false;
            }
        },
        logout: function () {
            this.loggingOut = true;
            this.$store.commit('player/setPlaying', false);
            PlayerEvents.$emit('pause')
            var self = this;
            axios.get("/api/auth/logout").then(function (response) {
                self.loggingOut = false;
                self.$store.dispatch("cart/reset");
                self.$store.commit("app/unsetUser");
                self.$router.push("/");
            });
        },
        notificationHandler() {
            let userId = this.app.user.id;
            window.Echo.private('App.User.' + userId)
                .notification((notification) => {
                    this.fetchNotifications();
                    console.log(notification);
                    // this.notifications.push(notification);
                    // this.notificationsCount += 1;
                });
        },
        fetchNotifications() {
            let userId = this.app.user.id;
            axios.get('/api/user/notifications/' + userId)
                .then(function (response) {
                    const notificationsArray = Object.values(response.data);

                    this.notifications = notificationsArray;
                    const notificationsCount = notificationsArray.reduce((count, notification) => {
                        return count + (notification.read_at === null ? 1 : 0);
                    }, 0);

                    this.notificationsCount = notificationsCount;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },

        handleDismissNotification(data) {
            axios.get(`/api/user/notifications/read/` + data.notificationId)
                .then(function (response) {
                    this.notifications.splice(data.index, 1);
                    this.notificationsCount--;
                    this.fetchNotifications();
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
        handleClearAllNotification() {
            axios.get('/api/user/notifications/read/all')
                .then(function (response) {
                    this.notifications = [];
                    this.notificationsCount = 0;
                    this.fetchNotifications();
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    components: {
        PhButton,
        Logo,
        LogoLines,
        MessageDropdown,
        NotificationDropdown
    }
};
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.fa-layers-counter {
    font-size: 40px;
}

#nav_wrapper {
    z-index: 100;

    header.top-bar {
        @media (max-width: 1000px) {
            display: none;
        }

        .logo-bars-block {
            display: flex;
            justify-content: space-between;
            width: 12%;
            align-items: center;

            .top {
                font-size: 30px;
                padding-left: 2rem;
                color: white;
            }
        }

        height: 80px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 20;

        &.searching {
            background: white;
            // justify-content: flex-start;
            // border-bottom: 1px solid $color-secondary;
        }

        .search-toggle {
            font-size: 2rem;
            width: 80px;
            height: 80px;
            line-height: 80px;
            box-sizing: border-box;
            cursor: pointer;
            color: $color-primary;
            // flex: 1;
            padding-left: 1em;

            &.white {
                color: white;
            }

            &.searching {
                text-align: center;
                // padding-left: 0;
                flex: initial;
                // background: white;
                color: $color-primary;
                // border-right: 1px solid $color-secondary;
            }
        }

        .logo {
            display: block;
            text-decoration: none;
            color: initial;
            // flex: 3;
            text-align: center;

            p {
                font-weight: bold;
                margin-left: 93px;
                margin-top: -14px;
            }
        }

        .user-actions {
            padding-right: 32px;
            display: flex;
            align-items: center;

            // flex: 1;
            // justify-content: flex-end;
            img {
                width: 27px;
                height: 27px;
            }

            div:first-of-type {
                padding-right: 15px;
            }

            &.right-padded {
                padding-right: 2em;
            }
        }

        .user-loggedIn-actions a {
            font-size: 30px;
        }

        .user-loggedIn-actions {
            display: flex;
            align-items: baseline;
            // width: 285px;
            gap: 25px;
            justify-content: space-between;
        }

        .verify-account-notify {
            border: 2px solid red;
            padding: 6px;
            border-radius: 5px;
            font-size: 16px;
        }
    }

    header.responsive {
        justify-content: space-between;
        background: white;
        padding: 20px;
        opacity: 0;
        display: none;

        @media (max-width: 1000px) {
            display: flex;
            align-items: center;
            opacity: 1;
            transition: 1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }

        svg {
            &:hover {
                cursor: pointer;
            }
        }

        .mobile-toggle {
            svg {
                width: 50px;
                height: 32px;
                margin-left: -11px;
            }
        }

        .search-toggle {
            svg {
                height: 35px;
                width: 27px;
            }
        }
    }

    .main-search {
        display: flex;
        align-items: center;
        width: 40%;
        margin: auto;
    }

    .search-input {
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        padding: 10px;
    }

    .bars-primary {
        color: #3523fb !important;
    }

    .top-bar-border-bottom {
        border-bottom: 2px solid #3300ff;
    }
}

.loggedout-search-icon {
    width: 100% !important;
    position: absolute;
}

.search-close {
    color: #3300ff;
    font-size: 26px;
    margin-right: 20px;
    cursor: pointer;
}
</style>
