var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-item" }, [
    _c(
      "div",
      { staticClass: "p-item-image" },
      [
        _c("avatar", {
          attrs: { size: 130, src: _vm.post.user.avatar.files.thumb.url },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-item-main" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.post.type,
            expression: "post.type",
          },
        ],
        attrs: { type: "hidden" },
        domProps: { value: _vm.post.type },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.post, "type", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "p-post-text" }, [
        _c("div", { staticClass: "p-item-detail" }, [
          _vm.isEditing
            ? _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editedPostBody,
                    expression: "editedPostBody",
                  },
                ],
                staticClass: "form-control post-editable-input",
                attrs: { rows: "5", cols: "60" },
                domProps: { value: _vm.editedPostBody },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.editedPostBody = $event.target.value
                  },
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.postBody != "" && !_vm.isEditing
          ? _c("div", { staticClass: "p-item-detail" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.postBody) + "\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.attachment
          ? _c("div", { staticClass: "p-post-text p-post-attachment" }, [
              _vm.post.attachment
                ? _c("img", {
                    staticClass: "p-post-image",
                    attrs: {
                      src: _vm.post.attachment.files.medium.url,
                      alt: _vm.post.attachment.alt,
                    },
                  })
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-item-meta" },
        [
          !_vm.isEditing
            ? _c("actions", {
                attrs: { actionable: _vm.post, id: _vm.id, actiontype: "post" },
                on: { "edit-post": _vm.enableEditMode },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isEditing
            ? _c(
                "div",
                { staticClass: "p-item-detail post-editable-actions" },
                [
                  _c(
                    "ph-button",
                    {
                      attrs: {
                        size: "small",
                        loading: _vm.submitting,
                        color: "primary-outline",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.savePost.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ph-button",
                    {
                      attrs: { size: "small", color: "danger-outline" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelEdit.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-time" }, [
            _vm.post.updated_at > _vm.post.created_at
              ? _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.moment(_vm.post.updated_at).fromNow()) +
                      " (edited)\n                "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.moment(_vm.post.created_at).fromNow()) +
                      "\n                "
                  ),
                ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }