var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "masonry-section" },
    [
      _c("div", [
        _c(
          "form",
          {
            staticClass: "w-full gridfilter clearboth mx-0 px-0",
            attrs: { action: "#", method: "GET", name: "gridfilter" },
          },
          [
            _c(
              "ul",
              {
                staticClass:
                  "gridfilter_list list-unstyled list-inline float-left",
              },
              [
                _c("li", [
                  _c(
                    "label",
                    { class: [_vm.selectedCategory === "all" ? "active" : ""] },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: { type: "radio", value: "all" },
                        domProps: {
                          checked: _vm._q(_vm.selectedCategory, "all"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = "all"
                          },
                        },
                      }),
                      _vm._v("All\n          "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    {
                      class: [_vm.selectedCategory === "track" ? "active" : ""],
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: { type: "radio", value: "track" },
                        domProps: {
                          checked: _vm._q(_vm.selectedCategory, "track"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = "track"
                          },
                        },
                      }),
                      _vm._v("Music\n          "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    {
                      class: [_vm.selectedCategory === "event" ? "active" : ""],
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: { type: "radio", value: "event" },
                        domProps: {
                          checked: _vm._q(_vm.selectedCategory, "event"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = "event"
                          },
                        },
                      }),
                      _vm._v("Events\n          "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    {
                      class: [_vm.selectedCategory === "video" ? "active" : ""],
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: { type: "radio", value: "video" },
                        domProps: {
                          checked: _vm._q(_vm.selectedCategory, "video"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = "video"
                          },
                        },
                      }),
                      _vm._v("Videos\n          "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    {
                      class: [_vm.selectedCategory === "merch" ? "active" : ""],
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: { type: "radio", value: "merch" },
                        domProps: {
                          checked: _vm._q(_vm.selectedCategory, "merch"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = "merch"
                          },
                        },
                      }),
                      _vm._v("Merch\n          "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    {
                      class: [_vm.selectedCategory === "post" ? "active" : ""],
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: { type: "radio", value: "post" },
                        domProps: {
                          checked: _vm._q(_vm.selectedCategory, "post"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = "post"
                          },
                        },
                      }),
                      _vm._v("Posts\n          "),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "float-right gridfilter_list_count" }, [
              _vm._v(_vm._s(_vm.filteredItemCount)),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.selectedCategory === "all"
        ? _c("add-text", {
            attrs: { type: "post", addTextAble: _vm.user },
            on: { success: _vm.addStatusUpdate },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.selectedCategory == "all" || _vm.selectedCategory == "post"
              ? "mas-grid"
              : "mas-grid-filtered",
          ],
        },
        _vm._l(_vm.filteredGrid, function (item, index) {
          return _c(item.component, {
            key: item.id + item.component + index,
            tag: "component",
            attrs: { item: item, selectedCategory: _vm.selectedCategory },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }