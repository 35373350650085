var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.event
    ? _c("div", { staticClass: "page-content-padded page-event-view" }, [
        _c("div", { staticClass: "page-main page-event" }, [
          _c("div", { staticClass: "main-info" }, [
            _c(
              "div",
              { staticClass: "links-block" },
              [
                _vm.event && _vm.app?.user
                  ? _c(
                      "router-link",
                      { attrs: { to: "/user/" + _vm.app.user.path } },
                      [
                        _c("div", { staticClass: "event-image" }, [
                          _c("div", { staticClass: "event-label" }, [
                            _c("i", {
                              staticClass: "fa fa-arrow-left",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" Go back to profile\n            "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("a", { attrs: { href: _vm.event?.url } }, [_vm._m(0)]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item-info" }, [
              _c("div", { staticClass: "item-info-upper" }, [
                _c("div", { staticClass: "item-info-upper-left" }, [
                  _c("div", { staticClass: "event-body" }, [
                    _vm.event.image
                      ? _c("div", { staticClass: "event-image" }, [
                          _vm.event.image
                            ? _c("img", {
                                staticClass: "p-event-image",
                                attrs: {
                                  src: _vm.event.image.files.medium.url,
                                  alt: _vm.event.image.alt,
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-details" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.event.name))]),
                      _vm._v(" "),
                      _c("span", [
                        _c("i", {
                          staticClass: "fa fa-map-pin",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" " + _vm._s(_vm.event.location)),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _c("i", {
                          staticClass: "fa fa-calendar",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" " + _vm._s(_vm.formatDate(_vm.event.date))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "event-author" },
                    [
                      _c("h2", [_vm._v("By")]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticStyle: { display: "flex", gap: "5px" },
                          attrs: { to: "/user/" + _vm.event.user.path },
                        },
                        [
                          _c("avatar", {
                            attrs: {
                              src: _vm.event.user.avatar.files.medium.url,
                              size: 50,
                              center: false,
                            },
                          }),
                          _vm._v(" "),
                          _c("h3", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.event.user.name) +
                                "\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _c("dotlottie-player", {
        staticStyle: { width: "200px", height: "200px", margin: "auto" },
        attrs: {
          src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      })
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "event-image" }, [
      _c("div", { staticClass: "event-label" }, [
        _vm._v("\n              Go to event "),
        _c("i", {
          staticClass: "fa fa-arrow-right",
          attrs: { "aria-hidden": "true" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }