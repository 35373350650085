var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-create-event",
        width: "800px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-create-event" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-create-event")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Create a new event")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                Use this form to register your externally organised events with Phase so your followers can see what\n                you're planning.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "event-options" }, [
            _c(
              "div",
              { staticClass: "event-image" },
              [
                _c("image-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min-dimensions:300,300",
                      expression: "'required|min-dimensions:300,300'",
                    },
                  ],
                  attrs: { name: "image" },
                  model: {
                    value: _vm.data.image,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "image", $$v)
                    },
                    expression: "data.image",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("image"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "event-info" },
              [
                _c("form", [
                  _c("table", [
                    _c("tr", [
                      _c("td", [_vm._v("Title")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.name,
                              expression: "data.name",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:255",
                              expression: "'required|max:255'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "name",
                            placeholder: "Name",
                          },
                          domProps: { value: _vm.data.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("name"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Location")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.location,
                              expression: "data.location",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:255",
                              expression: "'required|max:255'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "location",
                            placeholder: "Location",
                          },
                          domProps: { value: _vm.data.location },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "location",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("location"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("URL")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.url,
                              expression: "data.url",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|url|max:255",
                              expression: "'required|url|max:255'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "url",
                            name: "url",
                            placeholder: "URL (http://example.com)",
                          },
                          domProps: { value: _vm.data.url },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "url", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("url"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Date")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("v-date-picker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "form-control event-date",
                            attrs: {
                              "input-props": _vm.inputProps,
                              "min-date": new Date(),
                              name: "date",
                              masks: { input: "DD/MM/YYYY" },
                              popover: { placement: "top" },
                            },
                            model: {
                              value: _vm.data.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "date", $$v)
                              },
                              expression: "data.date",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(_vm._s(_vm.errors.first("date"))),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ph-button",
                  {
                    attrs: {
                      size: "medium",
                      loading: _vm.submitting,
                      color: "primary-outline",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }