<template>
  <div class="page-content-padded" v-if="video">
    <div class="page-main page-video">
      <div class="main-info">
        <div class="video-breadcrumbs-block">
          <router-link :to="'/user/' + video.user.path" v-if="video && video?.user">
            <avatar :src="video.user.avatar.files.medium.url" :size="34" :center="false" />
          </router-link>
          <i class="fa fa-angle-right"></i>
          <router-link :to="'/user/' + video.user.path + '/videos'" v-if="video && video?.user">
            <span>videos</span>
          </router-link>
          <i class="fa fa-angle-right"></i>
          <span>{{ truncatedBody }}</span>
        </div>
        <div class="item-info">
          <div class="item-info-upper">
            <div class="item-info-upper-left">

              <div class="video-body">
                <h3>{{ video.title }}</h3>
                <p>{{ video.description }}</p>
                <video controls width="100%" height="415" :id="'video' + video.id"
                  :poster="video.asset.files.video_thumbnail.url" preload="metadata">
                  <source :src="video.asset.files.original.url" :type="video.asset.files.original.mime" />
                </video>
              </div>

              <div class="video-author">
                <hr>
                <div class="video-author-action-row">
                  <router-link :to="'/user/' + video.user.path" style="display: flex;gap: 10px;align-items: center;">
                    <avatar :src="video.user.avatar.files.medium.url" :size="26" :center="false" />
                    <span>{{ video.user.name }}</span>
                    <span>{{ formattedDate }}</span>
                  </router-link>
                  <div class="video-author-action-row">
                    <social-sharing inline-template>
                      <span style="display: flex;font-size: 30px;gap: 12px;margin-right: 12px;">
                        <network network="twitter">
                          <i class="fab fa-twitter-square" style="cursor: pointer;"></i>
                        </network>
                        <network network="facebook">
                          <i class="fab fa-facebook" style="cursor: pointer;"></i>
                        </network>
                      </span>
                    </social-sharing>
                    <actions :actionable="video" :id="id" :actiontype='"video"' :isSingle="true">
                    </actions>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="comments">
        <comments-container :commentable="video" />
      </div>
    </div>
  </div>
  <dotlottie-player v-else src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
    background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto;" loop
    autoplay></dotlottie-player>

</template>

<script>
import 'mediaelement/full';
import { mapState } from 'vuex';
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import SidebarGroup from 'global/sidebar-group';
import CommentsContainer from "global/comments-container";
import store from 'store';
import Actions from 'global/actions/actions';


export default {
  components: {
    SidebarGroup,
    Spinner,
    CommentsContainer,
    Actions
  },
  mounted: function () {
    // new MediaElementPlayer(document.getElementById('video' + this.$route.params.videoid)), {
    //   success: function () {
    //   }
    // }
  },
  data() {
    return {
      video: null,
      isEditing: false,
      editedVideoTitle: '',
      editedVideoBody: '',
    }
  },
  computed: {
    ...mapState(['app']),
    truncatedBody() {
      return this.video.title.length > 20
        ? this.video.title.substring(0, 20) + "..."
        : this.video.title;
    },
    formattedDate() {
      return moment(this.video.created_at).format('MMM Do, YYYY');
    }
  },
  created: function () {
    this.fetchVideo();
  },
  methods: {
    fetchVideo() {
      axios.get('/api/video/' + this.$route.params.videoid).then(response => {
        this.video = response.data.video;

        setTimeout(() => {
          this.embedVideo();
        }, 2000);

      });
    },
    embedVideo() {
      new MediaElementPlayer(document.getElementById('video' + this.video?.id), {
        success: function () {
        }
      });
    },
  },

}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h1 {
  font-size: 35px;
}

.page-video {
  padding: 3em;
}

.main-info {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

.video-label {
  background-color: #e6e6e6;
  font-weight: bold;
  margin: 0 0 10px;
  border-radius: 8px;
  padding: 10px 12px;
  max-width: 168px;
}

.item-info {
  margin: 1.5rem 0;
  // flex: 1;
  display: flex;
  width: 100%;
  gap: 10px;
}

.item-info-upper {
  display: flex;
  background-color: #E6E6E6;
  padding: 10px;
  width: 100%;
}

.item-info-upper-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
}

h1 {
  margin: 0;
}

.video-body {
  font-size: 20px;
  margin: 1em 0;
  color: #000000;
  line-height: 30px;
  min-height: 10vh;
}
.video-body p{
  margin-bottom: 1em;
}

td {
  padding: 15px 0;
  text-align: center;
}

.comments {
  margin: 3em 0;
}

.video-author {
  // display: inline-flex;
  // justify-content: flex-start;
  // gap: 5px;
  align-items: center;
  margin-top: auto;
}

.video-author h2 {
  color: $color-grey2;
}

.video-breadcrumbs-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.video-breadcrumbs-block span {
  color: #3523FB;
}

.video-author-action-row {
  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.actions {
  font-size: 20px;
  gap: 4px;
}
</style>