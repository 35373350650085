var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: "masonry-item " + _vm.item.component + "-" + _vm.item.id,
      on: {
        mouseover: function ($event) {
          _vm.hovered = _vm.item.id
        },
        mouseleave: function ($event) {
          _vm.hovered = null
        },
      },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hovered === _vm.item.id,
                expression: "hovered === item.id",
              },
            ],
            staticClass: "overlay",
          },
          [
            _c(
              "div",
              { staticClass: "button-container" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button foot_button mint small",
                    attrs: { to: _vm.getRouterObject(_vm.item) },
                  },
                  [_vm._v("View Playlist")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "masonry-image masonry-image-release",
        attrs: { src: _vm.imgUrl, alt: this.item.name },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "masonry-inner masonry-inner-fixed" }, [
        _c("h4", [_vm._v("Playlist")]),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.item.name))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.limitChars(_vm.item.description)))]),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "list-unstyled clearfix" }, [
      _c("li", [_vm._v("Example Track Name Here")]),
      _vm._v(" "),
      _c("li", [_vm._v("Example Track Name Here")]),
      _vm._v(" "),
      _c("li", [_vm._v("Example Track Name Here")]),
      _vm._v(" "),
      _c("li", [_vm._v("Example Track Name Here")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }