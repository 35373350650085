<template>
  <div class="notification-dropdown" v-if="show">
    <span class="notification-arrow"><i class="fa fa-angle-down"></i></span>
    <div class="clear-all-text" @click="$emit('clearall')" v-if="notifications.length > 0">Clear all</div>
    <ul class="notification-list" v-if="notifications.length > 0">
      <li v-for="(notification, index) in notifications">
        <div class="notification">
          <div class="notification-detail">
            <div v-html="notification?.data?.message"></div>
            <div class="notification-actions">
              <span @click="$emit('dismiss', { notificationId: notification.id, index: index })">Dismiss</span>
              <!-- <router-link :to="notification.data.link"> -->
              <a :href="notification?.data?.link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else>No notifications</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["show", "notifications"],

  computed: mapState(["app"]),
  data() {
    return {
      // notifications: null,
    }
  },
  mounted() {
    // this.fetch();
  },

  methods: {
    // fetch() {
    //   let userId = this.app.user.id;
    //   axios.get('/api/user/notifications/' + userId)
    //     .then(function (response) {
    //       this.notifications = response.data;
    //     }.bind(this))
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // dismiss(id) {
    //   axios.get(`/api/user/notifications/read/` + id)
    //     .then(function (response) {
    //       this.notifications.splice(this.index, 1);
    //       this.fetch();
    //     }.bind(this))
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.notification-arrow{
  background: darken($color-grey, 3);
  position: absolute;
  top: -22px;
  right: 122px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px 8px;
}
.notification-dropdown {
  width: 250px;
  background: darken($color-grey, 3);
  position: absolute;
  right: 255px;
  top: 82px;
  padding: 0 1em;
  z-index: 999;

  &>div:last-child {
    margin: 0.7em 0;
    text-align: center;

    a {
      text-decoration: none;
    }
  }

  .notification {
    border-bottom: 1px solid white;
    display: flex;
    padding: 0.7em 0;
    text-decoration: none;

    .notification-avatar {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin-right: 15px;
      background-size: cover;
    }

    .notification-detail {
      margin-left: 10px;
      display: flex;
      font-size: 14px;
      flex-direction: column;

      //justify-content: space-between;
      .underline_none {
        text-decoration: none;
      }
    }

    .notification-name {
      color: $color-grey2;
      font-size: 10px;

      span {
        color: $color-blue;
        font-weight: bold;
        font-size: 12px;
      }
    }

    .notification-excerpt {
      margin-top: 5px;
      font-size: 10px;
    }

    .notification-actions {
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      margin-top: 5px;
      color: #3300ff;
      gap: 20px;
      cursor: pointer;
      align-items: center;

      .action_link {
        text-decoration: underline;
        margin-right: 10px;
        font-weight: bold;
      }

      .action_link:hover {
        cursor: pointer;
      }
    }
  }
}

.notification-list {
  overflow-y: auto;
  max-height: 300px;
}

/* Customize scrollbar */
.notification-list::-webkit-scrollbar {
  width: 3px;
  /* Width of the scrollbar */
}

.notification-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Grey color for the scrollbar track */
}

.notification-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
  /* Grey color for the scrollbar thumb */
}

.clear-all-text {
  width: 100%;
  text-align: right;
  text-decoration: underline;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
}
</style>
