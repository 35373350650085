var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.merch
    ? _c("div", { staticClass: "page-content-padded page-merch-view" }, [
        _c("div", { staticClass: "page-main page-merch" }, [
          _c("div", { staticClass: "main-info" }, [
            _c(
              "div",
              { staticClass: "merch-breadcrumbs-block" },
              [
                _vm.merch && _vm.app?.user
                  ? _c(
                      "router-link",
                      { attrs: { to: "/user/" + _vm.app.user.path } },
                      [
                        _c("avatar", {
                          attrs: {
                            src: _vm.merch.user.avatar.files.medium.url,
                            size: 34,
                            center: false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "fa fa-angle-right" }),
                _vm._v(" "),
                _vm.merch && _vm.app?.user
                  ? _c(
                      "router-link",
                      {
                        attrs: { to: "/user/" + _vm.app.user.path + "/merch" },
                      },
                      [_c("span", [_vm._v("Merch")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "fa fa-angle-right" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.truncatedBody))]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item-info" }, [
              _vm.merch.image
                ? _c("div", [
                    _vm.merch.image
                      ? _c("img", {
                          staticClass: "p-merch-image",
                          attrs: {
                            src: _vm.merch.image.files.medium.url,
                            alt: _vm.merch.image.alt,
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "item-info-upper" }, [
                _c("div", { staticClass: "item-info-upper-left" }, [
                  _c("div", { staticClass: "title-header" }, [
                    _c("div", { staticClass: "title-header-row" }, [
                      _c("h2", { staticStyle: { margin: "10px 0px" } }, [
                        _vm._v(_vm._s(_vm.merch.title)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "merch-buy-btn",
                          attrs: {
                            href: _vm.merch?.links[0].link,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v("Buy "),
                          _c("i", { staticClass: "fa fa-external-link" }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "merch-body" }, [
                    _c("p", [_vm._v(_vm._s(_vm.merch.description))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "merch-author" }, [
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "merch-author-action-row" },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: {
                              display: "flex",
                              gap: "10px",
                              "align-items": "center",
                            },
                            attrs: { to: "/user/" + _vm.merch.user.path },
                          },
                          [
                            _c("avatar", {
                              attrs: {
                                src: _vm.merch.user.avatar.files.medium.url,
                                size: 26,
                                center: false,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.merch.user.name))]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.formattedDate))]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "merch-author-action-row" },
                          [
                            _c("social-sharing", {
                              inlineTemplate: {
                                render: function () {
                                  var _vm = this,
                                    _c = _vm._self._c
                                  return _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "font-size": "30px",
                                        gap: "12px",
                                        "margin-right": "12px",
                                      },
                                    },
                                    [
                                      _c(
                                        "network",
                                        { attrs: { network: "twitter" } },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fab fa-twitter-square",
                                            staticStyle: { cursor: "pointer" },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "network",
                                        { attrs: { network: "facebook" } },
                                        [
                                          _c("i", {
                                            staticClass: "fab fa-facebook",
                                            staticStyle: { cursor: "pointer" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                },
                                staticRenderFns: [],
                              },
                            }),
                            _vm._v(" "),
                            _c("actions", {
                              attrs: {
                                actionable: _vm.merch,
                                id: _vm.merch.id,
                                actiontype: "merch",
                                isSingle: true,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _c("dotlottie-player", {
        staticStyle: { width: "200px", height: "200px", margin: "auto" },
        attrs: {
          src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }