<template>
  <div class="page-content-padded page-discover-index">
    <div class="page-main">

      <!-- <div v-if="showMessage" class="alert alert-success" style="background: green; color: #fff; padding: 10px;">Email
        verified successfully.</div> -->

      <!-- <div class="grid">
        <div class="item item--1x1">1. 1w X 1t</div>
        <div class="item item--1x2">2. 1w x 2t</div>
        <div class="item item--2x2">3. 2w x 2t</div>
        <div class="item item--2x1">4. 2w x 1t</div>
        <div class="item item--1x1">5. 1w X 1t</div>
        <div class="item item--1x2">6. 1w x 2t</div>
        <div class="item item--2x2">7. 2w x 2t</div>
        <div class="item item--2x1">8. 2w x 1t</div>
        <div class="item item--1x1">9. 1w X 1t</div>
        <div class="item item--1x2">10. 1w x 2t</div>
        <div class="item item--2x2">11. 2w x 2t</div>
        <div class="item item--2x1">12. 2w x 1t</div>
      </div> -->

      <masonry-grid v-if="app.feed.length" v-bind:feed_items="app.feed" />
      <!-- <spinner style="margin: 3em auto;" v-else :animation-duration="1000" :size="80" color="black" /> -->
      <div class="phase-loading" v-else>
        <dotlottie-player src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
          background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
        <!-- <img src="/img/phase-loading.gif" alt="" srcset=""> -->
      </div>
    </div>
    <aside class="sidebar-right">
      <sidebar-group title="News" :items="news.articles.slice(0, 5)"></sidebar-group>
    </aside>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import SidebarGroup from 'global/sidebar-group';
import MasonryGrid from 'global/feed/masonry-grid';
import store from 'store';
import {SocialEvents} from "../../../event-bus";


export default {
  components: {
    SidebarGroup,
    Spinner,
    MasonryGrid
  },
  data() {
    return {
      loadedAll: false,
    }
  },
  computed: mapState([
    'app',
    'news',
  ]),
  created: function () {
    this.fetchFeed();
  },
  methods: {
    fetchFeed() {
      store.dispatch('app/fetchFeed')
        .then(() => {
          this.loadedAll = true
        });
    }
  },
  mounted() {
    //   if (this.$route.query['email-verified'] === '1') {

    //     // this.showMessage = true
    //     //   setTimeout(() => {
    //     //     this.showMessage = false;
    //     //     this.$router.push('/');
    //     //   }, 15000) // hide message after 15 seconds
    //     axios.get("/api/auth/logout").then(function (response) {
    //       console.log("logged in");
    //       this.$store.commit("app/unsetUser");
    //       // this.$notify({
    //       //   group: 'main',
    //       //   type: 'success',
    //       //   title: '<img src="/img/Resized_mail.gif" alt="success" style="width=80%">',
    //       // })
    //       this.$router.push('/login');
    //     });

    //   }
  },
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 20vh;
  grid-gap: 2rem;
  grid-auto-flow: dense;
  padding: 2rem;
}

.item {
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item--1x2 {
  grid-row: auto / span 2;
}

.item--2x2 {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}

.item--2x1 {
  grid-column: auto / span 2;
}
</style>
