var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "feed-action", class: _vm.index % 2 === 0 ? "" : "alt" },
      [
        _c("div", { staticClass: "action-info" }, [
          _c("div", [
            _vm.type("user_followed_user")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " started following\n                        " +
                      _vm._s(_vm.action.item.name) +
                      "\n                    "
                  ),
                ])
              : _vm.type("user_posted_status_update") &&
                _vm.action.created_by === _vm.thisUser.id
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " posted\n                    "
                  ),
                ])
              : _vm.type("user_posted_status_update") &&
                _vm.action.created_by !== _vm.thisUser.id
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.action.item.user.name) +
                      " posted on\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      "'s profile\n                    "
                  ),
                ])
              : _vm.type("user_shared_item")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " shared a\n                        " +
                      _vm._s(_vm.action.item.shareable.type) +
                      "\n                    "
                  ),
                ])
              : _vm.type("user_avatar_updated")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " uploaded a new avatar\n                    "
                  ),
                ])
              : _vm.type("user_uploaded_release")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " uploaded a new release\n                    "
                  ),
                ])
              : _vm.type("user_liked_item")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " liked this " +
                      _vm._s(_vm.action.item.type) +
                      "\n                    "
                  ),
                ])
              : _vm.type("user_created_event")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " created a new event\n                    "
                  ),
                ])
              : _vm.type("user_created_merch")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " created a new merch\n                    "
                  ),
                ])
              : _vm.type("user_uploaded_video")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " uploaded a video\n                    "
                  ),
                ])
              : _vm.type("user_placed_order")
              ? _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.thisUser.name) +
                      " placed an order\n                    "
                  ),
                ])
              : _vm.type("user_submitted_report")
              ? _c("p", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.thisUser.name) +
                      " submitted " +
                      _vm._s(_vm.action.item.type) +
                      "\n                  "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.action.item_type != "share"
          ? _c("item", { attrs: { item: _vm.action.item, id: _vm.action.id } })
          : _c("item", {
              attrs: { item: _vm.action.item.shareable, id: _vm.action.id },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }