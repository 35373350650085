var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dotlottie-player", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingMerch,
            expression: "loadingMerch",
          },
        ],
        staticStyle: { width: "200px", height: "200px", margin: "auto" },
        attrs: {
          src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      }),
      _vm._v(" "),
      _vm.merches.length
        ? _c(
            "div",
            _vm._l(_vm.merches, function (merch) {
              return _c("item", { key: merch.id, attrs: { item: merch } })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.merches.length && !_vm.loadingMerch
        ? _c(
            "div",
            [
              !_vm.isShow
                ? _c("span", { staticClass: "not-found" }, [
                    _vm._v(
                      "\n      Sorry, this user hasn’t uploaded any merchandise!\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isShow && _vm.isPro
                ? _c(
                    "ph-button",
                    {
                      staticStyle: { display: "block", "text-align": "center" },
                      attrs: { size: "medium" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showMerchCreateModal()
                        },
                      },
                    },
                    [_vm._v("Add Merch")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }