var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "masonry-item " + _vm.item.component + "-" + _vm.item.id },
    [
      _c("router-link", { attrs: { to: _vm.getRouterObject(_vm.item) } }, [
        _c("img", {
          staticClass: "masonry-image masonry-image-release",
          attrs: {
            src: _vm.item.image ? _vm.item.image.files.thumb.url : _vm.imgUrl,
            alt: this.item.name,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "masonry-inner masonry-inner-fixed" }, [
          _c("h4", [_vm._v("Event")]),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.item.name))]),
          _vm._v(" "),
          _c("h3", [_c("span", [_vm._v(_vm._s(_vm.item.location))])]),
          _vm._v(" "),
          _c("div", { staticClass: "date-xxl" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.moment(_vm.item.date).format("MMM Do")) +
                "\n            "
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }