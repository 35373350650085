var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-update-post",
        width: "800px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { "before-open": _vm.beforeOpen, "before-close": _vm.resetForm },
    },
    [
      _c("div", { staticClass: "modal modal-update-post" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-update-post")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Update post")]),
          _vm._v(" "),
          _c("div", { staticClass: "merch-options" }, [
            _c(
              "div",
              { staticClass: "merch-image" },
              [
                _vm.post && _vm.post.attachment && !_vm.editImage
                  ? _c("avatar", {
                      attrs: {
                        size: 247,
                        src: _vm.post.attachment?.files.medium.url,
                        tile: true,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.editImage
                  ? _c("image-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "min-dimensions:300,300",
                          expression: "'min-dimensions:300,300'",
                        },
                      ],
                      attrs: { name: "image" },
                      model: {
                        value: _vm.data.image,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "image", $$v)
                        },
                        expression: "data.image",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.post && _vm.post.attachment
                  ? _c(
                      "div",
                      { staticClass: "edit-image-button" },
                      [
                        !_vm.editImage
                          ? _c(
                              "ph-button",
                              {
                                attrs: { size: "medium" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editImage = !_vm.editImage
                                  },
                                },
                              },
                              [_vm._v("Change\n              Image")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("image"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "event-info" },
              [
                _c("form", [
                  _c("table", [
                    _c("tr", [
                      _c("td", [_vm._v("Body")]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "textarea",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.body,
                                expression: "data.body",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:255",
                                expression: "'required|max:255'",
                              },
                            ],
                            attrs: { name: "body", placeholder: "Body" },
                            domProps: { value: _vm.data.body },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.data, "body", $event.target.value)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.data.body))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("body"))),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ph-button",
                  {
                    attrs: {
                      size: "medium",
                      loading: _vm.submitting,
                      color: "primary-outline",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }