<template>
	<div class="charts">
		<filter-container>
			<div class="handle-actions">
				<span @click="toggleSearch">Search</span>
				<span class="active">Charts</span>
			</div>
			<filter-filter v-model="filters.filter" :single="true"></filter-filter>
			<class-filter v-model="filters.classes" :single="true"></class-filter>
			<genre-filter v-model="filters.genres" :single="true"></genre-filter>
		</filter-container>
		<div class="chart-results" v-if="loadedAll">
			<div>
				<div class="charts-section" v-if="!filters.classes.length || (currentClass == 'album')">
					<div class="header flex justify-between">
						<h2>Top Albums</h2>
						<a style="cursor:pointer" v-if="amount <= 7" @click.prevent="seeMore('album')">See more >></a>
					</div>
					<div class="chart-row">
						<div v-if="results.album" class="chart-result" v-for="(album, index) in results.album"
							:key="index">
							<release-tile :release="album" :size="150" mode="charts" :position="index"></release-tile>
						</div>
						<div class="not-found-div" v-if="!results.album || !results.album.length">
							No Albums Found
						</div>
					</div>
				</div>
				<div class="charts-section" v-if="!filters.classes.length || (currentClass == 'single')">
					<div class="header flex justify-between">
						<h2>Top Singles</h2>
						<a style="cursor:pointer" v-if="amount <= 7" @click.prevent="seeMore('single')">See more >></a>
					</div>
					<div class="chart-row">
						<div v-if="results.single" class="chart-result" v-for="(single, index) in results.single"
							:key="index">
							<release-tile :release="single" :size="150" mode="charts" :position="index"></release-tile>
						</div>
						<div class="not-found-div" v-if="!results.single || !results.single.length">
							No Singles Found
						</div>
					</div>
				</div>
				<div class="charts-section" v-if="!filters.classes.length || (currentClass == 'ep')">
					<div class="header flex justify-between">
						<h2>Top EPs/LPs</h2>
						<a style="cursor:pointer" v-if="amount <= 7" @click.prevent="seeMore('ep')">See more >></a>
					</div>
					<div class="chart-row">
						<div v-if="results.ep" class="chart-result" v-for="(ep, index) in results.ep">
							<release-tile :release="ep" :size="150" mode="charts" :position="index"></release-tile>
						</div>
						<div class="not-found-div" v-if="!results.ep || !results.ep.length">
							No EP's Found
						</div>
					</div>
				</div>
				<div class="charts-section" v-if="!filters.classes.length || (currentClass == 'compilation')">
					<div class="header flex justify-between">
						<h2>Top Compilations</h2>
						<a style="cursor:pointer" v-if="amount <= 7" class="see-more"
							@click.prevent="seeMore('compilation')">See more >></a>
					</div>
					<div class="chart-row">
						<div v-if="results.compilation" class="chart-result"
							v-for="(compilation, index) in results.compilation" :key="index">
							<release-tile :release="compilation" :size="150" mode="charts"
								:position="index"></release-tile>
						</div>
						<div class="not-found-div" v-if="!results.compilation || !results.compilation.length">
							No Compilations Found
						</div>
					</div>
				</div>
				<div class="charts-section" v-if="!filters.classes.length || (currentClass == 'sample')">
					<div class="header flex justify-between">
						<h2>Top Sample Packs</h2>
						<a style="cursor:pointer" v-if="amount <= 7" @click.prevent="seeMore('sample')">See more >></a>
					</div>
					<div class="chart-row">
						<div v-if="results.sample" class="chart-result" v-for="(sample, index) in results.sample"
							:key="index">
							<release-tile :release="sample" :size="150" mode="charts" :position="index"></release-tile>
						</div>
						<div class="not-found-div" v-if="!results.sample || !results.sample.length">
							No Sample Packs Found
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;margin: auto;" v-else>
			<!-- <spinner style="margin: 3em auto;" :animation-duration="1000" :size="60" color="black" /> -->
			<dotlottie-player src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
				background="transparent" speed="1" style="width: 150px; height: 150px;margin: auto;" loop
				autoplay></dotlottie-player>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { HalfCircleSpinner as Spinner } from "epic-spinners";

import FilterContainer from "global/filters/filter-container";
import FilterFilter from "global/filters/filter-filter";
import ClassFilter from "global/filters/class-filter";
import GenreFilter from "global/filters/genre-filter";

import ReleaseTile from "global/releases/release-tile";

export default {
	data() {
		return {
			loadedAll: false,
			amount: 12,
			filters: {
				classes: [],
				genres: [],
				filter: [],
			},
			results: {
				albums: [],
				singles: [],
				eps: [],
				compilations: [],
			},
		};
	},
	computed: {
		...mapState(["search"]),
		currentClass: function () {
			if (this.filters.classes.length > 0) {
				return this.filters.classes[0].val;
			}
			return '';
		},
	},
	created: function () {
		if (this.$route.query.filter) {
			this.filters.classes.push({
				name: this.capitalizeFirstLetter(this.$route.query.filter),
				val: this.$route.query.filter,
			});
		}
		this.fetchCharts();
	},
	watch: {
		filters: {
			handler: function () {
				this.fetchCharts();
			},
			deep: true,
		},
	},
	methods: {
		fetchCharts() {
			this.loadedAll = false;
			this.filters.newsearch = 1;
			axios.post(`/api/charts/${this.amount}`, this.filters).then((response) => {
				if (response.data.filters) {
					console.log(1111, JSON.stringify(response.data.filters), JSON.stringify(this.filters));
					if (JSON.stringify(response.data.filters) === JSON.stringify(this.filters)) {
						console.log(2222);
						this.results = response.data.returndata;
					}
				} else {
					console.log(333);
					this.results = response.data;
				}
				console.log(this.results, response.data);
				this.loadedAll = true;
			});
		},
		seeMore(className) {
			this.amount = 20;
			this.filters.classes.push({
				name: this.capitalizeFirstLetter(className),
				val: className,
			});
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleSearch: function () {
			if (!this.search.visible) {
				this.$store.commit("search/toggleSearch");
			}
		},
	},
	components: {
		Spinner,

		FilterContainer,
		FilterFilter,
		ClassFilter,
		GenreFilter,

		ReleaseTile,
	},
};
</script>

<style lang="scss" scoped>
.charts {
	display: flex;
	align-items: flex-start;

	@media (max-width: 900px) {
		display: block;
	}

	.chart-results {
		width: 100%;
		padding: 0 20px;

		.charts-section {
			margin: 40px 0;

			.header {
				margin-bottom: 20px;
				align-items: flex-start;

				h2 {
					margin: 0 10px 0 0;
				}
			}

			.chart-row {
				display: grid;
				grid-template-columns: repeat(5, 1fr);

				@media (max-width: 900px) {
					grid-template-columns: repeat(3, 1fr);
				}

				@media (max-width: 650px) {
					grid-template-columns: repeat(2, 1fr);
				}

				grid-gap: 10px;
			}
		}
	}

	.not-found-div {
		width: 800px;
		text-align: center;
		display: block;

		@media (max-width: 750px) {
			width: 600px;
		}

		@media (max-width: 400px) {
			width: 300px;
		}
	}
}

.handle-actions {
	display: flex;
	justify-content: space-between;
	border: 2px solid #3522FB;
	margin-top: 12px;
	padding: 2px;
}

.handle-actions span {
	font-weight: 700;
	padding: 14px;
	flex: 1;
	font-size: 22px;
	background-color: #fff;
	color: #3522FB;
	cursor: pointer;
}

.handle-actions span.active {
	background-color: #3522FB;
	color: #fff;
}
</style>
