var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.video
    ? _c("div", { staticClass: "page-content-padded" }, [
        _c("div", { staticClass: "page-main page-video" }, [
          _c("div", { staticClass: "main-info" }, [
            _c(
              "div",
              { staticClass: "video-breadcrumbs-block" },
              [
                _vm.video && _vm.video?.user
                  ? _c(
                      "router-link",
                      { attrs: { to: "/user/" + _vm.video.user.path } },
                      [
                        _c("avatar", {
                          attrs: {
                            src: _vm.video.user.avatar.files.medium.url,
                            size: 34,
                            center: false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "fa fa-angle-right" }),
                _vm._v(" "),
                _vm.video && _vm.video?.user
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/user/" + _vm.video.user.path + "/videos",
                        },
                      },
                      [_c("span", [_vm._v("videos")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "fa fa-angle-right" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.truncatedBody))]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item-info" }, [
              _c("div", { staticClass: "item-info-upper" }, [
                _c("div", { staticClass: "item-info-upper-left" }, [
                  _c("div", { staticClass: "video-body" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.video.title))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.video.description))]),
                    _vm._v(" "),
                    _c(
                      "video",
                      {
                        attrs: {
                          controls: "",
                          width: "100%",
                          height: "415",
                          id: "video" + _vm.video.id,
                          poster: _vm.video.asset.files.video_thumbnail.url,
                          preload: "metadata",
                        },
                      },
                      [
                        _c("source", {
                          attrs: {
                            src: _vm.video.asset.files.original.url,
                            type: _vm.video.asset.files.original.mime,
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "video-author" }, [
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "video-author-action-row" },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: {
                              display: "flex",
                              gap: "10px",
                              "align-items": "center",
                            },
                            attrs: { to: "/user/" + _vm.video.user.path },
                          },
                          [
                            _c("avatar", {
                              attrs: {
                                src: _vm.video.user.avatar.files.medium.url,
                                size: 26,
                                center: false,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.video.user.name))]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.formattedDate))]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "video-author-action-row" },
                          [
                            _c("social-sharing", {
                              inlineTemplate: {
                                render: function () {
                                  var _vm = this,
                                    _c = _vm._self._c
                                  return _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "font-size": "30px",
                                        gap: "12px",
                                        "margin-right": "12px",
                                      },
                                    },
                                    [
                                      _c(
                                        "network",
                                        { attrs: { network: "twitter" } },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fab fa-twitter-square",
                                            staticStyle: { cursor: "pointer" },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "network",
                                        { attrs: { network: "facebook" } },
                                        [
                                          _c("i", {
                                            staticClass: "fab fa-facebook",
                                            staticStyle: { cursor: "pointer" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                },
                                staticRenderFns: [],
                              },
                            }),
                            _vm._v(" "),
                            _c("actions", {
                              attrs: {
                                actionable: _vm.video,
                                id: _vm.id,
                                actiontype: "video",
                                isSingle: true,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "comments" },
            [_c("comments-container", { attrs: { commentable: _vm.video } })],
            1
          ),
        ]),
      ])
    : _c("dotlottie-player", {
        staticStyle: { width: "200px", height: "200px", margin: "auto" },
        attrs: {
          src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }