var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-update-merch",
        width: "800px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-update-merch" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-update-merch")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Update merch")]),
          _vm._v(" "),
          _c("div", { staticClass: "merch-options" }, [
            _c(
              "div",
              { staticClass: "merch-image" },
              [
                _vm.merch && !_vm.editImage
                  ? _c("avatar", {
                      attrs: {
                        size: 247,
                        src: _vm.merch.image.files.medium.url,
                        tile: true,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.editImage
                  ? _c("image-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "min-dimensions:300,300",
                          expression: "'min-dimensions:300,300'",
                        },
                      ],
                      attrs: { name: "image" },
                      model: {
                        value: _vm.data.image,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "image", $$v)
                        },
                        expression: "data.image",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-image-button" },
                  [
                    !_vm.editImage
                      ? _c(
                          "ph-button",
                          {
                            attrs: { size: "medium" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.editImage = !_vm.editImage
                              },
                            },
                          },
                          [_vm._v("Change\n              Image")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("image"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "event-info" },
              [
                _c("form", [
                  _c("table", [
                    _c("tr", [
                      _c("td", [_vm._v("Title")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.title,
                              expression: "data.title",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:255",
                              expression: "'required|max:255'",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: "title",
                            placeholder: "Title",
                          },
                          domProps: { value: _vm.data.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "title", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("title"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Description")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.description,
                              expression: "data.description",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:255",
                              expression: "'required|max:255'",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: "description",
                            placeholder: "Description",
                          },
                          domProps: { value: _vm.data.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("description"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Links")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.link,
                              expression: "data.link",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|url|max:255",
                              expression: "'required|url|max:255'",
                            },
                          ],
                          attrs: {
                            type: "link",
                            name: "link",
                            placeholder: "Link (http://example.com)",
                          },
                          domProps: { value: _vm.data.link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "link", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("link"))),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ph-button",
                  {
                    attrs: {
                      size: "medium",
                      loading: _vm.submitting,
                      color: "primary-outline",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }