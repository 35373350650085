var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.postBody != ""
    ? _c(
        "div",
        {
          class:
            "p-item masonry-item " + _vm.item.component + "-" + _vm.item.id,
        },
        [
          _c(
            "div",
            { staticClass: "p-item-head" },
            [
              _c(
                "router-link",
                { attrs: { to: _vm.getRouterObject(_vm.item) } },
                [
                  _c("avatar", {
                    attrs: {
                      size: 60,
                      src: _vm.item.user.avatar.files.thumb.url,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "p-item-detail" }, [
                _c("div", { staticClass: "p-item-title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.item.user.name))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "p-item-date-time" }, [
                    _vm._v(
                      "Posted " + _vm._s(_vm.formatDate(_vm.item.created_at))
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-main" }, [
            _c("div", { staticClass: "p-post-text" }, [
              _vm.item.attachment
                ? _c("img", {
                    staticClass: "p-post-image",
                    attrs: {
                      src: _vm.item.attachment.files.medium.url,
                      alt: _vm.item.attachment.alt,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "p-item-body" }, [
                _vm._v(_vm._s(_vm.postBody)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-item-meta" },
              [
                _c("div", { staticClass: "p-item-counts" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.comments_count) + " comments"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.item.likes_count) + " Likes")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.shares_count) + " shares"),
                  ]),
                ]),
                _vm._v(" "),
                _c("actions", {
                  attrs: {
                    actionable: _vm.item,
                    id: _vm.item.action_id,
                    isFeed: true,
                  },
                  on: { "delete-action": _vm.deletedItem },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }