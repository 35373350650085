<template>
    <div>
        <div class="search-breadcrumb" v-show="search.fromSearch"><span @click="handleSearchActions()">Search</span> >
            <span>{{ release?.name }}</span></div>
        <track-release
            v-if="(release && release?.status === 'live' && !release?.freezed) || (release && app.user.all_permissions.includes('view admin dashboard') && !release?.freezed)"
            :item="release" />
        <div v-if="release && release?.status !== 'live' && !app.user.all_permissions.includes('view admin dashboard') && !release?.freezed"
            class="center-content">
            This release has not been approved yet.
        </div>
        <div v-if="release && release?.freezed" class="center-content">
            This release has been frozen.
        </div>
        <dotlottie-player v-if="!release" src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
            background="transparent" speed="1" style="width: 150px; height: 150px;margin: 3em auto;" loop
            autoplay></dotlottie-player>
    </div>
</template>

<script>
import TrackRelease from './track-release';
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import { mapState, mapMutations } from "vuex";

export default {
    data() {
        return {
            release: null,
        }
    },
    computed: {
        ...mapState(["app", "search"]),
    },
    created: function () {
        this.fetchRelease();
    },
    watch: {
        '$route.params.releaseid': function (newReleaseId) {
            this.release = null;
            this.fetchRelease();
        }
    },
    methods: {
        ...mapMutations({
            setFromSearch: 'search/setFromSearch',
            setToggleSearch: 'search/toggleSearch'
        }),
        fetchRelease() {
            axios.get('/api/release/' + this.$route.params.releaseid).then(response => {
                this.release = response.data;
            });
        },
        handleSearchActions() {
            console.log(this.search.searchTerm);
            this.setToggleSearch(true);
        }
    },
    components: {
        TrackRelease,
        Spinner,
    },
    beforeRouteLeave(to, from, next) {
        if (this.search.fromSearch) {
            this.setFromSearch(false);
        }
        next();
    }
}
</script>

<style lang="scss" scoped>
.center-content {
    display: flex;
    width: 100vw;
    height: 50vh;
    justify-content: center;
    align-items: center;
}
</style>

<style>
.search-breadcrumb {
    max-width: 1600px;
    margin: 0 auto;
    margin-top: 20px;
}

.search-breadcrumb span {
    color: #3523FB;
    font-weight: 600;
    cursor: pointer;
}
</style>
